import React, { useEffect, useState } from 'react';
import './ProjectNavigationMenu.scss';

const ProjectNavigationMenu = () => {
  const [activeItem, setActiveItem] = useState('Vacation Rentals');

  const PROJECT_NAVIGATION_ITEMS = [
    { name: 'MAK Realty', active: false, url: 'https://makrealty.com/' },
    {
      name: 'Vacation Rentals',
      active: true,
      url: '',
    },
    {
      name: 'Miami Penthouses',
      active: false,
      url: 'https://penthouse.makrealty.com',
    },
  ];

  const handleURLChange = () => {
    if (window.location.href.includes('fontainebleau')) {
      setActiveItem('Miami Penthouses');
    } else {
      setActiveItem('Vacation Rentals');
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', handleURLChange);

    window.addEventListener('hashchange', handleURLChange);

    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(this, args);
      handleURLChange();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(this, args);
      handleURLChange();
    };

    return () => {
      window.removeEventListener('popstate', handleURLChange);
      window.removeEventListener('hashchange', handleURLChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  return (
    <div className="project-navigation-menu">
      <div className="menu-container">
        {PROJECT_NAVIGATION_ITEMS.map(({ name, url }, index) =>
          url ? (
            <a
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              href={url}
              className={`menu-item ${activeItem === name ? 'active' : ''}`}
            >
              {name}
            </a>
          ) : (
            <p key={index} className={`menu-item ${activeItem === name ? 'active' : ''}`}>
              {name}
            </p>
          ),
        )}
      </div>
    </div>
  );
};

export default ProjectNavigationMenu;
