import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Api from "../../../Api";
import { Link, Redirect } from "react-router-dom";

export default class ListingAvailability extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listing: {},
            advance_notice_hours: null,
            minimum_nights: null,
            listingSaved: false,
        }

        this.fetchListing = this.fetchListing.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    fetchListing() {
        Api.get('/api/manager/listing/' + this.props.id)
            .then((response) => {
                this.setState({ listing: response.data }, () => {
                    this.setState({
                        advance_notice_hours: this.state.listing.advance_notice_hours,
                        minimum_nights: this.state.listing.minimum_nights,
                    });
                });
            })
            .catch((e) => {
                console.log(e);
            })
    }

    componentDidMount() {
        this.fetchListing();
    }

    submitForm(e) {
        e.preventDefault();
        Api.post('/api/manager/listing/' + this.props.id + '/availability', {
            advance_notice_hours: this.state.advance_notice_hours,
            minimum_nights: this.state.minimum_nights,
        })
            .then((response) => {
                console.log(response);
                this.setState({ listingSaved: true });
            })
            .catch((e) => {
                console.log(e);
            })
    }

    render() {
        return <>
            { this.state.listingSaved && <Redirect to="/manager/listings" /> }
            <Container className="mb-1em top-container"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <h2 className="pull-left">Availability settings for <i>{this.state.listing.name}</i></h2>

                <div>
                    <Link to={"/manager/listings"} className="btn btn-outline-primary pull-right back-btn">← Back to list</Link>
                    <Link to={"/manager/listings/update/" + this.props.id} className="btn btn-primary pull-right">Edit listing</Link>
                </div>
            </Container>
            <Container className="create-listing-form">
                <Form onSubmit={this.submitForm}>
                    <Row>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Advance notice hours</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter advance notice hours"
                                    onChange={(e) => this.setState({ advance_notice_hours: e.target.value })}
                                    required
                                    value={this.state.advance_notice_hours}
                                />
                                <Form.Text className="text-muted">
                                    How many hours in advance must a guest book a listing?
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Minimum stay</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter minimum stay"
                                    onChange={(e) => this.setState({ minimum_nights: e.target.value })}
                                    required
                                    value={this.state.minimum_nights}
                                />
                                <Form.Text className="text-muted">
                                    What is the minimum number of nights a guest can book?
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* submit area */}
                    <Row>
                        <Col md={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                            <Button className="pull-right" variant="primary" type="submit">Save</Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    }
}
