import React from 'react';
import { Offcanvas } from "react-bootstrap";
import '../../../../components/Header/Header.scss';
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/phone1.svg";
import { ReactComponent as PersonIcon } from "../../../../assets/icons/person.svg";
import { Link } from "react-router-dom";


const Menu = ({ toggleMenu, isOpen }) => {
    return (
        <Offcanvas show={isOpen} onHide={toggleMenu}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <a href={"https://vacations.makrealty.com/"}>
                        <img src="https://vacations.makrealty.com/wp-content/uploads/2022/05/MAK_Logo_Ver5_Dark_CMYK.png"
                            alt="" className={"logo"} />
                    </a>
                </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <a href={"https://vacations.makrealty.com/"} className={`${isOpen} ? 'active : ''`} >Home</a>
                <a href={"https://vacations.makrealty.com/list-your-property"}>List Your Property</a>
                <a href={"https://vacations.makrealty.com/concierge"}>Concierge</a>
                <a href={"https://vacations.makrealty.com/blog"}>Blog</a>
                <a href={"https://vacations.makrealty.com/contact"}>Contact</a>
                <a className={"fw-bold"} href={"https://vacations.book.makrealty.com"}>Book Now</a>
            </Offcanvas.Body>
            <div className='offcanvas-footer'>
                <div>
                    <a href={"tel:1-(305)-204-6049"}>
                        <PhoneIcon />
                    </a>
                    <Link to={"/account"}><PersonIcon /></Link>
                </div>
            </div>
        </Offcanvas>
    )
}

export default Menu