import { STARTTOURS } from '../constants';
import StartTourItem from './StartTourItem';
import React from 'react';
import "../style.scss"

const StartTour = () => {

return (
  <div className="fb-startTour">
   
    <div className="fb-startTour-title">START 3D TOUR</div>
    <div className="fb-startTour-items">
      {STARTTOURS.map((item, index) => (
        <StartTourItem item={item} key={index} />
      ))}
    </div>
  </div>
)
}

export default StartTour