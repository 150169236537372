import React, { useEffect } from "react";
import { Dropdown, Form } from "react-bootstrap";
import "./Multiselect.scss";

export default function Multiselect({options, onChange, placeholder = ""}) {
    const [selected, setSelected] = React.useState([]);

    const formatSelected = () => {
        if (selected.length === 0) {
            return placeholder
        } else {
            return selected.join(", ")
        }
    }

    const handleChange = (option) => {
        if (selected.includes(option)) {
            setSelected(selected.filter((item) => item !== option));
        } else {
            setSelected([...selected, option]);
        }
    }

    useEffect(() => {
        onChange(selected);
    }, [selected]);

    return (
        <Dropdown className={"multiselect" + (selected.length ? "" : " empty")}>
            <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                {selected.length === 0 ? formatSelected() : selected.join(", ")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    options.map((option, index) => {
                        return (
                            <Dropdown.Item
                                key={index}
                                onClick={() => handleChange(option)}
                                className={selected.includes(option) ? "bg-primary text-white" : ""}
                            >
                                <Form.Check
                                    type="checkbox"
                                    label={option}
                                    checked={selected.includes(option)}
                                    disabled
                                    onChange={() => {}}
                                />
                            </Dropdown.Item>
                        );
                    })
                }
            </Dropdown.Menu>

        </Dropdown>
    )
}