import React, {Component} from 'react';
import './Loader.css';

export class Loader extends Component {
    render() {
        return (
            <div className="loader">
                <div className="loader__inner">
                    <div className="loader__content"><span className="spinner" /></div>
                </div>
            </div>
        )
    }
}