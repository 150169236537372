import React, { Component } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import './ListingForm.scss';
import { Form } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { amenities, additionalAmenities, handleInputChange, handleAmenityChange, readPicturesAsBase64, handleAddressChange, submitForm, fetchListing, handlePictureDescriptionChange } from "./utilities";

export class ListingUpdate extends Component {

    constructor(props) {
        super(props);

        this.amenities = amenities;
        this.additionalAmenities = additionalAmenities;

        this.state = {
            formData: {
                name: '',
                amenities: [],
                address: {
                    street1: '',
                    street2: '',
                    city: '',
                    state: '',
                    postal_code: '',
                    country: '',
                },
                meta_info: {}
            },
            images: [],
            imagesUploading: false,
            listingSaved: false,
            submitInProgress: false,
            formErrors: {
                meta_info: {},
            },
        }

        this.handleInputChange = handleInputChange.bind(this);
        this.readPicturesAsBase64 = readPicturesAsBase64.bind(this);
        this.handleAmenityChange = handleAmenityChange.bind(this);
        this.handleAddressChange = handleAddressChange.bind(this);
        this.submitForm = submitForm.bind(this);
        this.fetchListing = fetchListing.bind(this);
        this.handlePictureDescriptionChange = handlePictureDescriptionChange.bind(this);
        this.handleMetaInfoChange = this.handleMetaInfoChange.bind(this);
    }

    handleMetaInfoChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ formData: { ...this.state.formData, meta_info: { ...this.state.formData.meta_info, [name]: value } } });
    }

    componentDidMount() {
      this.fetchListing();
    }

    render() {
        return <>
            { this.state.listingSaved && <Redirect to="/manager/listings" /> }
            <Container className="mb-1em top-container">
                <h2 className="pull-left">Update listing <i>{this.state.formData.name}</i></h2>
                <Link to={"/manager/listings"} className="btn btn-outline-primary pull-right back-btn">← Back to list</Link>
            </Container>
            <Container className="create-listing-form">

                <Form onSubmit={this.submitForm}>
                    <fieldset>
                        <legend>Main info</legend>
                        <Row>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Title</Form.Label>
                                <Form.Control value={this.state.formData.name} type="text" placeholder="Enter title" required onChange={this.handleInputChange}
                                    name="name" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.name}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Nickname</Form.Label>
                                <Form.Control value={this.state.formData.nickname} type="text" placeholder="Enter nickname" required onChange={this.handleInputChange}
                                    name="nickname" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.nickname}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label>Tagline</Form.Label>
                                <Form.Control value={this.state.formData.tagline} type="text" placeholder="Enter tagline" onChange={this.handleInputChange} name="tagline" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.tagline}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label>Description</Form.Label>
                                <Form.Control value={this.state.formData.description} as="textarea" rows={4} required name="description" onChange={this.handleInputChange} />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.description}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </fieldset>

                    <fieldset>
                        <legend>Address</legend>
                        <Row>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Street</Form.Label>
                                <Form.Control value={this.state.formData.address.street1} type="text" placeholder="Enter street" required onChange={this.handleAddressChange}
                                    name="street1" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors['address.street1']}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Street line #2</Form.Label>
                                <Form.Control value={this.state.formData.address.street2} type="text" placeholder="Enter street line #2" onChange={this.handleAddressChange}
                                    name="street2" />
                                    <Form.Control.Feedback type="invalid">{this.state.formErrors['address.street2']}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>City</Form.Label>
                                <Form.Control value={this.state.formData.address.city} type="text" placeholder="Enter city" onChange={this.handleAddressChange}
                                    name="city" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors['address.city']}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>State</Form.Label>
                                <Form.Control value={this.state.formData.address.state} type="text" placeholder="Enter state" onChange={this.handleAddressChange}
                                    name="state" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors['address.state']}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Postal Code</Form.Label>
                                <Form.Control value={this.state.formData.address.postal_code} type="text" placeholder="Enter postal code" onChange={this.handleAddressChange}
                                    name="postal_code" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors['address.postal_code']}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Country</Form.Label>
                                <Form.Control value={this.state.formData.address.country} type="text" placeholder="Enter country" onChange={this.handleAddressChange}
                                    name="country" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors['address.country']}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </fieldset>

                    <fieldset>
                        <legend>Details</legend>
                        <Row>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Room Type</Form.Label>
                                <Form.Control value={this.state.formData.room_type} as="select" onChange={this.handleInputChange} name="room_type" required>
                                    <option value="">Select room type</option>
                                    <option value="Private room">Private room</option>
                                    <option value="Entire home/apt">Entire home/apt</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.room_type}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Square feet</Form.Label>
                                <Form.Control value={this.state.formData.square_feet} step="0.01" type="number" placeholder="Enter square feet" onChange={this.handleInputChange}
                                    name="square_feet" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.square_feet}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Number of bedrooms</Form.Label>
                                <Form.Control value={this.state.formData.bedrooms} type="number" placeholder="Enter number of bedrooms" onChange={this.handleInputChange}
                                    name="bedrooms" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.bedrooms}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Number of bathrooms</Form.Label>
                                <Form.Control
                                    value={this.state.formData.bathrooms}
                                    type="number"
                                    placeholder="Enter number of bathrooms"
                                    onChange={this.handleInputChange}
                                    name="bathrooms"
                                    min="0"
                                    step="0.5"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.bathrooms}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Number of beds</Form.Label>
                                <Form.Control value={this.state.formData.beds} type="number" placeholder="Enter number of beds" onChange={this.handleInputChange}
                                    name="beds" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.beds}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </fieldset>

                    <fieldset>
                        <legend>Pictures</legend>
                        <Row>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label className="btn btn-outline-primary">
                                  Upload pictures
                                  <Form.Control style={{ display: 'none' }} type="file" multiple onChange={this.readPicturesAsBase64} name="pictures" />
                                </Form.Label>
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.pictures}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <div className="pictures-preview row">
                                    {this.state.images.map((picture, index) => {
                                        return (
                                            <>
                                                <div className="col-md-3 picture-block">
                                                    <div key={index} className="picture-preview">
                                                        <img src={picture.base64 ? picture.base64 : picture.src} alt="preview" />
                                                    </div>
                                                    <Form.Control type="text" placeholder="Enter picture description" onChange={(e) => this.handlePictureDescriptionChange(e, picture)} value={picture.name ? picture.name : ''} />
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </fieldset>

                    <fieldset>
                        <legend>Amenities</legend>
                        <Row>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label>Amenities</Form.Label>
                                <Row>
                                    {this.amenities.map((amenity, index) => {
                                        return (
                                            <Col md={4} key={index}>
                                                <Form.Check
                                                    checked={this.state.formData.amenities.includes(amenity)}
                                                    value={amenity} id={"amenity-" + index} key={index} type="checkbox" label={amenity} onChange={this.handleAmenityChange}
                                                    name="amenities" />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label>Additional amenities</Form.Label>
                                <Row>
                                    {this.additionalAmenities.map((amenity, index) => {
                                        return (
                                            <Col md={4} key={index}>
                                                <Form.Check
                                                    checked={this.state.formData.amenities.includes(amenity)}
                                                    value={amenity} id={"additional-amenity-" + index} key={index} type="checkbox" label={amenity} onChange={this.handleAmenityChange}
                                                    name="amenities" />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Form.Group>
                        </Row>
                    </fieldset>

                    <fieldset>
                        <legend>Check-in, Price and Taxes</legend>
                        <Row>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Check-in time</Form.Label>
                                <Form.Control value={this.state.formData.check_in_time} type="time" onChange={this.handleInputChange} name="check_in_time" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.check_in_time}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Check-out time</Form.Label>
                                <Form.Control value={this.state.formData.check_out_time} type="time" onChange={this.handleInputChange} name="check_out_time" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.check_out_time}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Number of guests</Form.Label>
                                <Form.Control value={this.state.formData.number_of_guests} type="number" placeholder="Enter number of guests" onChange={this.handleInputChange}
                                    name="number_of_guests" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.number_of_guests}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Price per night</Form.Label>
                                <Form.Control value={this.state.formData.minimum_price} step="0.01" type="number" placeholder="Enter price per night" onChange={this.handleInputChange}
                                    name="minimum_price" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.minimum_price}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Tax rate</Form.Label>
                                <Form.Control value={this.state.formData.tax_rate} step="0.01" type="number" placeholder="Enter tax rate" onChange={this.handleInputChange}
                                    name="tax_rate" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.tax_rate}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Weekly price factor</Form.Label>
                                <Form.Control value={this.state.formData.weekly_price_factor} step="0.01" type="number" placeholder="Enter weekly price factor" onChange={this.handleInputChange}
                                    name="weekly_price_factor" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.weekly_price_factor}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Monthly price factor</Form.Label>
                                <Form.Control value={this.state.formData.monthly_price_factor} step="0.01" type="number" placeholder="Enter monthly price factor" onChange={this.handleInputChange}
                                    name="monthly_price_factor" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.monthly_price_factor}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Cleaning fee</Form.Label>
                                <Form.Control
                                    value={this.state.formData.cleaning_fee}
                                    step="0.01" type="number" placeholder="Enter cleaning fee" onChange={this.handleInputChange}
                                    name="cleaning_fee" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.cleaning_fee}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Pet fee</Form.Label>
                                <Form.Control
                                    value={this.state.formData.pet_fee}
                                    step="0.01" type="number" placeholder="Enter pet fee" onChange={this.handleInputChange} name="pet_fee"
                                  />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.pet_fee}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Guest security deposit</Form.Label>
                                <Form.Control
                                    value={this.state.formData.guest_security_deposit}
                                    step="0.01" type="number" placeholder="Enter guest security deposit" onChange={this.handleInputChange}
                                    name="guest_security_deposit"  />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.guest_security_deposit}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Extra guest fee</Form.Label>
                                <Form.Control
                                    value={this.state.formData.extra_guest_fee}
                                    step="0.01" type="number" placeholder="Enter extra guest fee" onChange={this.handleInputChange}
                                    name="extra_guest_fee"  />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.extra_guest_fee}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={4}>
                                <Form.Label>No of Guests after fee is applied</Form.Label>
                                <Form.Control
                                    value={this.state.formData.extra_guest_fee_treshold}
                                    step="1" type="number" placeholder="Enter No of Guests after fee is applied" onChange={this.handleInputChange}
                                    name="extra_guest_fee_treshold"
                                    required={this.state.formData.extra_guest_fee ? true : false}
                                />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.extra_guest_fee_treshold}</Form.Control.Feedback>
                            </Form.Group>

                        </Row>

                    </fieldset>

                    <fieldset>
                        <legend>Additional information</legend>
                        <Row>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Space description</Form.Label>
                                <Form.Control
                                    value={this.state.formData.the_space}
                                    as="textarea" rows="3" onChange={this.handleInputChange} name="the_space"
                                     />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.the_space}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Guest access</Form.Label>
                                <Form.Control
                                    value={this.state.formData.guest_access}
                                    as="textarea" rows="3" onChange={this.handleInputChange} name="guest_access"
                                     />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.guest_access}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>The Neighborhood</Form.Label>
                                <Form.Control
                                    value={this.state.formData.the_neighborhood}
                                    as="textarea" rows="3" onChange={this.handleInputChange} name="the_neighborhood"
                                     />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.the_neighborhood}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Getting around</Form.Label>
                                <Form.Control
                                    value={this.state.formData.getting_around}
                                    as="textarea" rows="3" onChange={this.handleInputChange} name="getting_around"
                                     />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.getting_around}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label>Other things to note</Form.Label>
                                <Form.Control
                                    value={this.state.formData.other_notes}
                                    as="textarea" rows="3" onChange={this.handleInputChange} name="other_notes"
                                     />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.other_notes}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </fieldset>

                    <fieldset>
                        <legend>Misc settings</legend>
                        <Row>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Email confirmation CC</Form.Label>
                                <Form.Control
                                    value={this.state.formData.meta_info.email_confirmation_cc}
                                    type="email" placeholder="Enter email confirmation CC" onChange={this.handleMetaInfoChange}
                                    name="email_confirmation_cc"
                                    required={this.state.formData.manual_approve_booking}
                                />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.meta_info ? this.state.formErrors.meta_info.email_confirmation_cc : ''}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>AirBnB iCal Link</Form.Label>
                                <Form.Control
                                    value={this.state.formData.meta_info.airbnb_ical_url}
                                    type="text" placeholder="Enter AirBnB iCal Link" onChange={this.handleMetaInfoChange}
                                    name="airbnb_ical_url"  />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.meta_info ? this.state.formErrors.meta_info.airbnb_ical_url : ''}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Manual confirmation required</Form.Label>
                                <Form.Switch className="mt-3" name="manual_approve_booking" onChange={(e) => {this.setState({formData: {...this.state.formData, manual_approve_booking: e.target.checked}})}} checked={this.state.formData.manual_approve_booking} />
                            </Form.Group>
                        </Row>
                    </fieldset>
                    <Row>
                        <Col className="submit-area" xs={12}>
                        <Button variant="primary" type="submit" disabled={this.state.submitInProgress}>
                                {
                                    this.state.submitInProgress ?
                                        <>
                                            <Spinner animation="border" size="sm" />
                                            <span> Saving, please wait...</span>
                                        </>
                                        : 'Save Listing'
                                }
                            </Button>
                        </Col>
                    </Row>

                </Form>
            </Container>
        </>
    }
  }
