import React, {Component} from 'react';
import {toast} from "react-hot-toast";
import Api from "../../Api";
import {Loader} from "../../components/Loader/Loader";
import {Redirect} from "react-router-dom";

export class Logout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            processing: true
        }
    }

    componentDidMount() {

        Api.post(
            '/logout'
        ).then(response => {
            toast.success("You have successfully logged out.");
            this.props.updateFunction();
        }).catch((error) => {
            toast.error(error.response.data.message);
        }).finally(response => {
            this.setState ({ processing: false });
        });

    }

    render() {
        if (!this.props.user) return <Redirect to={"/login"} />
        return (<Loader/>)
    }

}