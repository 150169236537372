import { getDaysInMonth, getWeeksInMonth, getDay, startOfMonth, isBefore } from 'date-fns';
import { DateRangePickerSettings, ICalendarDay } from '../interfaces/date-range-picker';

/**
 * return month calendar data with the following structure:
 * {
 *      today: {
            todayDate: Date Object,
            todayDay: number from 1 to 31,
            todayMonth: number from 0 to 11, January is 0, December is 11,
            todayYear: four digits, for example 2018,
        },

        monthWithYearTitle: string like 'April 2020',
        calendarMonth: number from 0 to 11, January is 0, December is 11,
        calendarYear: four digits, for example 2018,
        weeks: [
            [{isEmpty: boolean, dayIndex: number, isToday: boolean, isBeforeToday: boolean, isAfterToday: boolean}, ...],
            [{isEmpty: boolean, dayIndex: number, isToday: boolean, isBeforeToday: boolean, isAfterToday: boolean}, ...],
            ...
        ]
 * }
 *
 * @param {number} calendarMonth - from 0 to 11, January is 0, December is 11
 * @param {number} calendarYear - four digits, for example 2018
 *
 * @param {number} todayDay - from 1 to 31
 * @param {number} todayMonth - from 0 to 11, January is 0, December is 11
 * @param {number} todayYear - four digits, for example 2018
 * @param {number} weekStart - 0 for Sunday - 6 for Saturday
 * @param {Array.<string>=} monthNames2
 * @return {object}
 */
export const getMonthCalendarData = (
    calendarMonth: number,
    calendarYear: number,
    todayDay: number,
    todayMonth: number,
    todayYear: number,
    weekStart: number,
    monthNames2: string[]) => {

    const todayDate = new Date(todayYear, todayMonth, todayDay);
    const calendarDate = new Date(calendarYear, calendarMonth, 1);
    let monthWithYearTitle = '';

    if(monthNames2 && Array.isArray(monthNames2) && monthNames2.length === 12){
        const monthName = monthNames2[calendarDate.getMonth()];
        monthWithYearTitle = `${monthName} ${calendarDate.getFullYear()}`;
    }/*
    else{
        monthWithYearTitle = format(calendarDate, 'MMMM yyyy');
    }*/

    const weeks = getMonthWeeks(calendarDate, todayDate, weekStart);

    return {
        today: {
            todayDate,
            todayDay,
            todayMonth,
            todayYear
        },
        monthWithYearTitle,
        calendarMonth,
        calendarYear,
        weeks
    };
};

/**
 * get empty days at the beginning of the month calendar
 * @param {Date} calendarDate
 * @param {number} weekStart - 0 for Sunday - 6 for Saturday
 * @return {Array.<{isEmpty: boolean}>}
 */
export const getEmptyDaysOnStart = (calendarDate: Date, weekStart: number) => {

    // return the start of a month for the given date. For example for '15 September 2014' it returns '1 September 2014'
    const startOfMonthDate = startOfMonth(calendarDate);

    // index of day of the week, 0 = Sunday
    const firstIndex = getDay(startOfMonthDate);

    // get number of empty days
    let numberOfEmptyDays = firstIndex - weekStart;

    if(numberOfEmptyDays < 0) {
        numberOfEmptyDays += 7;
    }

    const emptyDays = [];

    for(let i=0; i< numberOfEmptyDays; i++){
        emptyDays.push({
            isEmpty: true,
            isEmptyOnStart: true
        });
    }

    return emptyDays;
};

/**
 * get empty days at the end of the month calendar
 * @param {Date} calendarDate
 * @return {Array.<{isEmpty: boolean}>}
 */
export const getEmptyDaysOnEnd = (calendarDate: Date) => {

    // return the start of a month for the given date. For example for '15 September 2014' it returns '1 September 2014'
    const startOfMonthDate = startOfMonth(calendarDate);

    // index of day of the week, 0 = Sunday
    const firstIndex = getDay(startOfMonthDate);

    // get number of days in moth
    const daysNumberInMonth = getDaysInMonth(calendarDate);

    // get weeks number
    const weeksNumber = getWeeksInMonth(calendarDate);

    const emptyDaysOnEndNumber = weeksNumber * 7 - daysNumberInMonth - firstIndex;

    const emptyDays = [];

    for(let i=0; i<emptyDaysOnEndNumber; i++){
        emptyDays.push({
            isEmpty: true,
            isEmptyOnEnd: true
        });
    }

    return emptyDays;
};

/**
 * get non empty month days
 * @param {Date} calendarDate
 * @param {Date} todayDate
 * @return {Array.<{isEmpty: boolean, dayIndex: number, isToday: boolean, isBeforeToday: boolean, isAfterToday: boolean}>}
 */
export const getMonthDays = (calendarDate: Date, todayDate: Date) => {

    const days: ICalendarDay[] = [];

    // number of days in moth
    const daysNumberInMonth = getDaysInMonth(calendarDate);

    for(let i=0; i<daysNumberInMonth; i++){

        days.push({
            isEmpty: false,
            dayIndex: i + 1, // from 1 to 31
        });
    }

    const isTodayYearAndMonth = (calendarDate.getFullYear() === todayDate.getFullYear()) && (calendarDate.getMonth() === todayDate.getMonth());

    if(isTodayYearAndMonth){

        const todayDayIndex = todayDate.getDate();

        for(let i=0; i<days.length; i++){

            if(i + 1 === todayDayIndex){
                days[i].isToday = true;
            }
            else{
                if(i + 1 < todayDayIndex){
                    days[i].isBeforeToday = true;
                }
                else{
                    days[i].isAfterToday = true;
                }
            }
        }
    }
    else{
        const calendarMonthIsBeforeTodayDate = isBefore(calendarDate, todayDate);

        for(let i=0; i<days.length; i++){

            if(calendarMonthIsBeforeTodayDate) {
                days[i].isBeforeToday = true;
            }
            else{
                days[i].isAfterToday = true;
            }
        }
    }

    return days;
};

/**
 * get month days divided into weeks
 * @param {Date} calendarDate
 * @param {Date} todayDate
 * @param {number=} weekStart - 0 for Sunday - 6 for Saturday
 * @return {Array.<Array.<{isEmpty: boolean, dayIndex: number, isToday: boolean, isBeforeToday: boolean, isAfterToday: boolean}>>}
 */
export const getMonthWeeks = (calendarDate: Date, todayDate: Date, weekStart = 0) => {

    const emptyDaysOnStart = getEmptyDaysOnStart(calendarDate, weekStart);
    const monthDays = getMonthDays(calendarDate, todayDate);
    const emptyDaysOnEnd = getEmptyDaysOnEnd(calendarDate);

    const days = [...emptyDaysOnStart, ...monthDays, ...emptyDaysOnEnd];

    // split days to weeks
    const weeks = [];
    const chunk = 7;

    for(let d=0; d<days.length; d += chunk){
        weeks.push(days.slice(d, d + chunk));
    }

    return weeks;
};

// -------------  RENDER ------------------

/**
 * get day of week index
 * @param {number} weekStart - 0 for Sunday - 6 for Saturday
 * @param {number} dayIndex - [0 ... 6]
 * @return {number}
 */
const getDayOfWeekIndex = (weekStart: number, dayIndex: number) => {
    return weekStart + dayIndex > 6 ? weekStart + dayIndex - 7 : weekStart + dayIndex;
};

/**
 * render one calendar month
 * @param {object} calendarMonthData
 * {
 *      today: {
            todayDate: Date Object,
            todayDay: number from 1 to 31,
            todayMonth: number from 0 to 11, January is 0, December is 11,
            todayYear: four digits, for example 2018,
        },

        monthWithYearTitle: string like 'April 2020',
        calendarMonth: number from 0 to 11, January is 0, December is 11,
        calendarYear: four digits, for example 2018,
        weeks: [
            [{isEmpty: boolean, dayIndex: number, isToday: boolean, isBeforeToday: boolean, isAfterToday: boolean}, ...],
            [{isEmpty: boolean, dayIndex: number, isToday: boolean, isBeforeToday: boolean, isAfterToday: boolean}, ...],
            ...
        ]
 * }
 * @param {
   {
        renderLeftArrow: boolean,
        renderRightArrow: boolean,
        dayAttributes: [{key: string, value: *}],
        dayNames2: Array.<string>,
        weekStart: number
   }=} settings
 * return {string}
 */
export const renderCalendarMonth = (calendarMonthData: any, settings: DateRangePickerSettings) => {

    if(!calendarMonthData) return '';

    const dayOfWeekClass = 'jso-calendar-day-of-week-title';

    // the day may have optional day attributes [{key:..., value:...}, ...{key:..., value:...}]
    let dyaAttributes = '';
    let dayNames2 = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    let weekStart = 0; // 0 for Sunday - 6 for Saturday

    if(settings){

        if(settings.dayAttributes){
            for(let i=0; i<settings.dayAttributes.length; i++){
                const dayAttr = settings.dayAttributes[i];
                dyaAttributes = ` ${dayAttr.key}="${dayAttr.value}" `;
            }
        }

        if(settings.dayNames2 && Array.isArray(settings.dayNames2) && settings.dayNames2.length === 7) {
            dayNames2 = settings.dayNames2;
        }

        if(settings.weekStart && !isNaN(settings.weekStart) && weekStart >= 0 && weekStart <= 6){
            weekStart = settings.weekStart;
        }
    }

    return `
 <table class="jso-calendar-month" data-month="${calendarMonthData.calendarMonth}" data-year="${calendarMonthData.calendarYear}">
    <thead>
        <tr>
            <th colspan="7" class="jso-calendar-month-title">
                ${settings && settings.renderLeftArrow ? `<button type="button" class="jso-calendar-month-arrow jso-calendar-month-arrow-left"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512"><polyline points="244 400 100 256 244 112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="48px"/><line x1="120" y1="256" x2="412" y2="256" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="48px" /></svg></button>` : ''}
                
                ${calendarMonthData.monthWithYearTitle}
                
                ${settings && settings.renderRightArrow ? `<button type="button" class="jso-calendar-month-arrow jso-calendar-month-arrow-right"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512"><polyline points="268 112 412 256 268 400" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="48px"/><line x1="392" y1="256" x2="100" y2="256" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="48px" /></svg></button>` : ''}
            </th>
        </tr>
        <tr>
            <th class="${dayOfWeekClass}">${dayNames2[getDayOfWeekIndex(weekStart, 0)]}</th>
            <th class="${dayOfWeekClass}">${dayNames2[getDayOfWeekIndex(weekStart, 1)]}</th>
            <th class="${dayOfWeekClass}">${dayNames2[getDayOfWeekIndex(weekStart, 2)]}</th>
            <th class="${dayOfWeekClass}">${dayNames2[getDayOfWeekIndex(weekStart, 3)]}</th>
            <th class="${dayOfWeekClass}">${dayNames2[getDayOfWeekIndex(weekStart, 4)]}</th>
            <th class="${dayOfWeekClass}">${dayNames2[getDayOfWeekIndex(weekStart, 5)]}</th>
            <th class="${dayOfWeekClass}">${dayNames2[getDayOfWeekIndex(weekStart, 6)]}</th>
        </tr>
        ${calendarMonthData.weeks.map((week: any) => {
            return `
            <tr class="jso-calendar-week">
                ${week.map((day: any) => {
                    
                    let dayClassName = ' jso-calendar-day ';
                    
                    if(day.isToday){
                        dayClassName += ' jso-calendar-today ';
                    }

                    if(day.isEmpty){
                        dayClassName += ' jso-calendar-empty-day ';
                    }
                    
                    if(day.isBeforeToday){
                        dayClassName += ' jso-calendar-before-today ';
                    }

                    if(day.isAfterToday){
                        dayClassName += ' jso-calendar-after-today ';
                    }

                    const isTabindex = !day.isEmpty && (day.isToday || day.isAfterToday);
                    
                    return `
                    <td ${isTabindex? 'tabindex="0"' : ''}
                        ${dyaAttributes ? dyaAttributes : ''}
                        class="${dayClassName}"
                        data-year="${calendarMonthData.calendarYear}"
                        data-month="${calendarMonthData.calendarMonth}"
                        data-day="${day.dayIndex === undefined ? -1 : day.dayIndex}">${day.isEmpty ? '&nbsp;' : day.dayIndex}</td>
                `;
                    
            }).join('')}
            </tr>
        `;
    }).join('')}
    </thead>
    
 </table>`;
};