import React, {Component} from 'react';
import {Button, Col, Modal, Row} from "react-bootstrap";
import {toast} from "react-hot-toast";
import Api from "../../Api";

export class ModifyReservationModal
    extends Component {

    constructor(props) {
        super(props)

        this.state = {
            show:       this.props.show,
            formData:   [],
            formErrors: []
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit      = this.handleSubmit.bind(this);
    }

    handleInputChange = (event) => {

        const target = event.target;
        const value  = target.type === 'checkbox' ? target.checked : target.value;
        const name   = target.name;

        let {
                formData   = [],
                formErrors = []
            } = this.state;

        formData[name]   = value;
        formErrors[name] = null;

        console.log(formData);

        this.setState({
                          formData:   formData,
                          formErrors: formErrors,
                      })


    }

    handleSubmit(event) {

        event.preventDefault();

        if (!this.state.formData.notes || !this.state.formData.notes.length) {
            toast.error("Please input notes about your modification.");
            return false;
        }

        let reservationId = this.props.reservation.id;

        let formData = new FormData();

        for (const key in
            this.state.formData) formData.append(key, this.state.formData[key]);

        this.setState({formProcessing: true});

        let loadingToast = toast.loading("Submitting");

        Api.post('/api/reservations/' + reservationId + '/request-modification', formData).then((response) => {
            console.log(response);
            toast.success("Your request has been submitted. We will be in touch shortly.", {id: loadingToast});
            this.props.toggleFunction();
        });

    }

    render() {

        const {user} = this.props;

        return (
            <Modal size="md" show={this.props.show} onHide={this.props.toggleFunction}>
                <Modal.Header closeButton>
                    <Modal.Title className={"fs-4 d-flex"}>
                        Modify / Cancel Reservation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <p>If you'd like to modify or cancel your reservation, feel free let us know in the box
                                below.
                                We will reach out to you to confirm your change.
                            </p>
                            <small>Please note that if you are looking to
                                cancel your reservation, the results will depend on our cancellation policy at the
                                time
                                of
                                your booking.</small>
                            <textarea onChange={this.handleInputChange} name={"notes"} rows={6}
                                      className={"mt-4 form-control"}
                                      placeholder={"Please enter the details for your modification or cancellation."}/>

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"secondary"} onClick={this.props.toggleFunction}>Close</Button>
                    <Button variant={"primary"} onClick={this.handleSubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}