import React from 'react';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function StarRating({ rating, starSize, className, style }) {
  const stars_html = [];

  let stars = 0;
  while (stars < 5) {
    if (Math.round(rating) >= stars + 1) {
      stars_html.push(<FontAwesomeIcon icon={solidStar} style={{ fontSize: starSize }} />);
    } else {
      stars_html.push(<FontAwesomeIcon icon={regularStar} style={{ fontSize: starSize }} />);
    }

    stars++;
  }

  return (
    <span className={className} style={style}>
      {stars_html}
    </span>
  );
}
