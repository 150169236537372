import React from 'react';

export const DatesIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
        <rect fill="#bfbfbf" x="272" y="208" width="48" height="48" rx="4" ry="4" />
        <rect fill="#bfbfbf" x="352" y="208" width="48" height="48" rx="4" ry="4" />
        <rect fill="#bfbfbf" x="272" y="288" width="48" height="48" rx="4" ry="4" />
        <rect fill="#bfbfbf" x="352" y="288" width="48" height="48" rx="4" ry="4" />
        <rect fill="#bfbfbf" x="112" y="288" width="48" height="48" rx="4" ry="4" />
        <rect fill="#bfbfbf" x="192" y="288" width="48" height="48" rx="4" ry="4" />
        <rect fill="#bfbfbf" x="112" y="368" width="48" height="48" rx="4" ry="4" />
        <rect fill="#bfbfbf" x="192" y="368" width="48" height="48" rx="4" ry="4" />
        <rect fill="#bfbfbf" x="272" y="368" width="48" height="48" rx="4" ry="4" />
        <path fill="#bfbfbf"
            d="M448,64H400V32H360V64H152V32H112V64H64A32,32,0,0,0,32,96V448a32,32,0,0,0,32,32H448a32,32,0,0,0,32-32V96A32,32,0,0,0,448,64ZM436,436H76V176H436Z" />
    </svg>
);

export const TravelersIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
        <path fill="#bfbfbf"
            d="M432,230.7a79.44,79.44,0,0,0-32-6.7H112a79.51,79.51,0,0,0-32,6.69h0A80.09,80.09,0,0,0,32,304V416a16,16,0,0,0,32,0v-8a8.1,8.1,0,0,1,8-8H440a8.1,8.1,0,0,1,8,8v8a16,16,0,0,0,32,0V304A80.09,80.09,0,0,0,432,230.7Z" />
        <path fill="#bfbfbf"
            d="M376,80H136a56,56,0,0,0-56,56v72a4,4,0,0,0,5.11,3.84A95.5,95.5,0,0,1,112,208h4.23a4,4,0,0,0,4-3.55A32,32,0,0,1,152,176h56a32,32,0,0,1,31.8,28.45,4,4,0,0,0,4,3.55h24.46a4,4,0,0,0,4-3.55A32,32,0,0,1,304,176h56a32,32,0,0,1,31.8,28.45,4,4,0,0,0,4,3.55H400a95.51,95.51,0,0,1,26.89,3.85A4,4,0,0,0,432,208V136A56,56,0,0,0,376,80Z" />
    </svg>
);
