import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import ListingCard from "./Cards/NewListingCard";
import {Redirect} from "react-router-dom";

export class SearchResults
    extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectLink: ''
        };
    }

    render() {

        const {
                  user,
                  filter,
                  selectedTab
              } = this.props;

        const rowEvents = {
            onClick: (
                         e,
                         row,
                         rowIndex
                     ) => {


                if (!window.getSelection().toString() && e.target.tagName !== "BUTTON" &&
                e.target.parentNode.tagName !== "BUTTON" && e.target.parentNode.parentNode.tagName !== "BUTTON")
                this.setState({ redirectLink: '/listings/'+row.id });


            }
        };

        return (
            <>
                {this.state.redirectLink !== '' ? <Redirect to={this.state.redirectLink}/> : <Row>
                    <Col>
                        <div className="content search-results">
                            {this.props.tableData.length > 0 && this.props.tableData.map((row) =>  (
                                    <ListingCard
                                        key={row.id}
                                        data={row}
                                        user={user}
                                        // onClick={rowEvents.onClick}
                                        onClick={this.props.toggleFunction}
                                        checkIn={this.props.checkIn}
                                        checkOut={this.props.checkOut}
                                        adults={this.props.adults}
                                        children={this.props.children}
                                        toggleFunction={this.props.toggleFunction}
                                        rating={row.rating ? row.rating.avg : 5}
                                    />
                                )
                            )}
                        </div>
                    </Col>
                </Row>
                }

            </>
        )
    }
}