import React, { Component } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import './ListingForm.scss';
import { Form } from "react-bootstrap";
import Api from "../../../Api";
import { Link, Redirect } from "react-router-dom";
import { amenities, additionalAmenities, handleInputChange, handleAmenityChange, handleAddressChange, readPicturesAsBase64, fetchListing, submitForm, fetchAdderssComponents, handlePictureDescriptionChange } from "./utilities";

export class ListingCreate extends Component {

    constructor(props) {
        super(props);

        this.amenities = amenities;
        this.additionalAmenities = additionalAmenities;

        this.state = {
            formData: {
                amenities: [],
                address: {
                    street1: '',
                    street2: '',
                    city: '',
                    state: '',
                    postal_code: '',
                    country: '',
                }
            },
            images: [],
            imagesUploading: false,
            listingSaved: false,
            submitInProgress: false,
            formErrors: {},
            countries: [],
            states: [],
            cities: [],
        }

        this.handleInputChange = handleInputChange.bind(this);
        this.readPicturesAsBase64 = readPicturesAsBase64.bind(this);
        this.handleAmenityChange = handleAmenityChange.bind(this);
        this.submitForm = submitForm.bind(this);
        this.handleAddressChange = handleAddressChange.bind(this);
        this.fetchListing = fetchListing.bind(this);
        this.fetchAddressComponents = fetchAdderssComponents.bind(this);
        this.handlePictureDescriptionChange = handlePictureDescriptionChange.bind(this);
    }

    componentDidMount() {
        if (!this.props.id) {
            Api.post('/api/manager/listing/create')
                .then(response => {
                    if (response.data.id) {
                        this.setState({ formData: { ...this.state.formData, id: response.data.id } }, () => {
                            console.log(this.state, response.data)
                            window.history.replaceState(null, null, `/manager/listings/create/${this.state.formData.id}`);
                        });
                    } else {
                        console.error('Error creating listing')
                    }
                }).catch(error => {
                    console.error(error);
                })
        } else {
            this.fetchListing();
        }

        this.fetchAddressComponents('countries');
        this.fetchAddressComponents('states');
        this.fetchAddressComponents('cities');
    }

    render() {
        return <>
            { this.state.listingSaved && <Redirect to="/manager/listings" /> }
            <Container className="mb-1em top-container">
                <h2 className="pull-left">Add listing</h2>
                <Link to={"/manager/listings"} className="btn btn-outline-primary pull-right back-btn">← Back to list</Link>
            </Container>
            <Container className="create-listing-form">

                <Form onSubmit={this.submitForm}>
                    <fieldset>
                        <legend>Main info</legend>
                        <Row>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Title</Form.Label>
                                <Form.Control value={this.state.formData.name} type="text" placeholder="Enter title" required onChange={this.handleInputChange}
                                    name="name" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.name}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Nickname</Form.Label>
                                <Form.Control value={this.state.formData.nickname} type="text" placeholder="Enter nickname" required onChange={this.handleInputChange}
                                    name="nickname" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.nickname}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label>Tagline</Form.Label>
                                <Form.Control value={this.state.formData.tagline} type="text" placeholder="Enter tagline" onChange={this.handleInputChange} name="tagline" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.tagline}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label>Description</Form.Label>
                                <Form.Control value={this.state.formData.description} as="textarea" rows={4} required name="description" onChange={this.handleInputChange} />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.description}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </fieldset>

                    <fieldset>
                        <legend>Address</legend>
                        <Row>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Street</Form.Label>
                                <Form.Control value={this.state.formData.address.street1} type="text" placeholder="Enter street" required onChange={this.handleAddressChange}
                                    name="street1" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors['address.street1']}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Street line #2</Form.Label>
                                <Form.Control value={this.state.formData.address.street2} type="text" placeholder="Enter street line #2" onChange={this.handleAddressChange}
                                    name="street2" />
                                    <Form.Control.Feedback type="invalid">{this.state.formErrors['address.street2']}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>City</Form.Label>
                                <Form.Control value={this.state.formData.address.city} type="text" placeholder="Enter city" onChange={this.handleAddressChange}
                                    name="city"
                                    list="cities"
                                />
                                <datalist id="cities">
                                    {this.state.cities.map((city, index) => <option key={index} value={city} />)}
                                </datalist>
                                <Form.Control.Feedback type="invalid">{this.state.formErrors['address.city']}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>State</Form.Label>
                                <Form.Control value={this.state.formData.address.state} type="text" placeholder="Enter state" onChange={this.handleAddressChange}
                                    name="state"
                                    list="states"
                                />
                                <datalist id="states">
                                    {this.state.states.map((state, index) => <option key={index} value={state} />)}
                                </datalist>
                                <Form.Control.Feedback type="invalid">{this.state.formErrors['address.state']}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Postal Code</Form.Label>
                                <Form.Control value={this.state.formData.address.postal_code} type="text" placeholder="Enter postal code" onChange={this.handleAddressChange}
                                    name="postal_code" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors['address.postal_code']}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Country</Form.Label>
                                <Form.Control value={this.state.formData.address.country} type="text" placeholder="Enter country" onChange={this.handleAddressChange}
                                    name="country"
                                    list="countries"
                                />
                                <datalist id="countries">
                                    {this.state.countries.map((country, index) => <option key={index} value={country} />)}
                                </datalist>
                                <Form.Control.Feedback type="invalid">{this.state.formErrors['address.country']}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </fieldset>

                    <fieldset>
                        <legend>Details</legend>
                        <Row>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Room Type</Form.Label>
                                <Form.Control value={this.state.formData.room_type} as="select" onChange={this.handleInputChange} name="room_type" required>
                                    <option value="">Select room type</option>
                                    <option value="Private room">Private room</option>
                                    <option value="Entire home/apt">Entire home/apt</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.room_type}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Label>Square feet</Form.Label>
                                <Form.Control value={this.state.formData.square_feet} step="0.01" type="number" placeholder="Enter square feet" onChange={this.handleInputChange}
                                    name="square_feet" />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.square_feet}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Number of bedrooms</Form.Label>
                                <Form.Control value={this.state.formData.bedrooms} type="number" placeholder="Enter number of bedrooms" onChange={this.handleInputChange}
                                    name="bedrooms" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.bedrooms}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Number of bathrooms</Form.Label>
                                <Form.Control
                                    value={this.state.formData.bathrooms}
                                    type="number"
                                    placeholder="Enter number of bathrooms"
                                    onChange={this.handleInputChange}
                                    name="bathrooms"
                                    min="0"
                                    step="0.5"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.bathrooms}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={4}>
                                <Form.Label>Number of beds</Form.Label>
                                <Form.Control value={this.state.formData.beds} type="number" placeholder="Enter number of beds" onChange={this.handleInputChange}
                                    name="beds" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.beds}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </fieldset>

                    <fieldset>
                        <legend>Pictures</legend>
                        <Row>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label className="btn btn-outline-primary">
                                  Upload pictures
                                  <Form.Control style={{ display: 'none' }} type="file" multiple onChange={this.readPicturesAsBase64} name="pictures" />
                                </Form.Label>
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.pictures}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <div className="pictures-preview row">
                                    {this.state.images.map((picture, index) => {
                                        return (
                                            <>
                                                <div className="col-md-3 picture-block">
                                                    <div key={index} className="picture-preview">
                                                        <img src={picture.base64 ? picture.base64 : picture.src} alt="preview" />
                                                    </div>
                                                    <Form.Control type="text" placeholder="Enter picture description" onChange={(e) => this.handlePictureDescriptionChange(e, picture)} value={picture.name ? picture.name : ''} />
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </fieldset>

                    <fieldset>
                        <legend>Amenities</legend>
                        <Row>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label>Amenities</Form.Label>
                                <Row>
                                    {this.amenities.map((amenity, index) => {
                                        return (
                                            <Col md={4} key={index}>
                                                <Form.Check
                                                    checked={this.state.formData.amenities.includes(amenity)}
                                                    value={amenity} id={"amenity-" + index} key={index} type="checkbox" label={amenity} onChange={this.handleAmenityChange}
                                                    name="amenities" />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label>Additional amenities</Form.Label>
                                <Row>
                                    {this.additionalAmenities.map((amenity, index) => {
                                        return (
                                            <Col md={4} key={index}>
                                                <Form.Check
                                                    checked={this.state.formData.amenities.includes(amenity)}
                                                    value={amenity} id={"additional-amenity-" + index} key={index} type="checkbox" label={amenity} onChange={this.handleAmenityChange}
                                                    name="amenities" />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Form.Group>
                        </Row>
                    </fieldset>

                    <fieldset>
                        <legend>Check-in, Price and Taxes</legend>
                        <Row>
                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Check-in time</Form.Label>
                                <Form.Control value={this.state.formData.check_in_time} type="time" onChange={this.handleInputChange} name="check_in_time" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.check_in_time}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Check-out time</Form.Label>
                                <Form.Control value={this.state.formData.check_out_time} type="time" onChange={this.handleInputChange} name="check_out_time" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.check_out_time}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Number of guests</Form.Label>
                                <Form.Control value={this.state.formData.number_of_guests} type="number" placeholder="Enter number of guests" onChange={this.handleInputChange}
                                    name="number_of_guests" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.number_of_guests}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Price per night</Form.Label>
                                <Form.Control value={this.state.formData.minimum_price} step="0.01" type="number" placeholder="Enter price per night" onChange={this.handleInputChange}
                                    name="minimum_price" required />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.minimum_price}</Form.Control.Feedback>
                            </Form.Group>

                        </Row>
                    </fieldset>

                    <Row>
                        <Col className="submit-area" xs={12}>
                            <Button variant="primary" type="submit" disabled={this.state.submitInProgress}>
                                {
                                    this.state.submitInProgress ?
                                        <>
                                            <Spinner animation="border" size="sm" />
                                            <span> Saving...</span>
                                        </>
                                        : 'Save Listing'
                                }
                            </Button>
                        </Col>
                    </Row>

                </Form>
            </Container>
        </>
    }
  }
