import React, {useEffect} from "react";
import {toast} from "react-hot-toast";

function SocialProofPopup(props) {

    useEffect(() => {

        var socialProofPopupTimeout = null;

        var socialProofPopupInterval = setInterval(function () {

            if (socialProofPopupTimeout) return false;  //  wait until currently scheduled bozo is completed

            var randomTimeout = (Math.random() * 300000) + 60000;   //  (random*x + y) where min is y and max is y+x
            console.log("Popup Timeout: " + randomTimeout);

            // clearTimeout(socialProofPopupTimeout)

            socialProofPopupTimeout = setTimeout(function () {

                toast.success("Someone just booked a stay at Fontainebleau Resort!", {
                    position: "bottom-left",
                    duration: 4 * 1000,
                    style:    {
                        backgroundColor: '#333',
                        color:           '#ddd',
                    },
                    icon:     '🔥',
                });

                socialProofPopupTimeout = null;

            }, randomTimeout);

        }, 1000);   //  must be greater than max value of randomTimeout.

        return () => clearInterval(socialProofPopupInterval);

    }, []);


    return (<></>);


}

export default SocialProofPopup;