import React, { Component } from "react";
import { Button, ButtonGroup, Container, Dropdown, OverlayTrigger, Pagination, SplitButton, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import './ListingsIndex.scss';
import Api from "../../../Api";
import { isObject } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function LinkWithTooltip({ id, children, to, tooltip, className }) {
    return (
      <OverlayTrigger
        overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
        placement="top"
        delayShow={300}
        delayHide={150}
      >
        <Link className={className} to={to}>{children}</Link>
      </OverlayTrigger>
    );
}

export class ListingsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paginatedListings: {data: []},
      page: 1,
      source: 'internal',
      status: 'all'
    }

    this.fetchItems = this.fetchItems.bind(this);
    this.destroyItem = this.destroyItem.bind(this);
    this.archiveItem = this.archiveItem.bind(this);
    this.formatAddress = this.formatAddress.bind(this);
  }

  componentDidMount() {
    this.fetchItems();
  }

  fetchItems() {
    Api.get(`/api/manager/listing?page=${this.state.page}&per_page=8&source=${this.state.source}&status=${this.state.status}`)
      .then((response) => {
        this.setState({ paginatedListings: response.data, page: response.data.current_page });
      })
      .catch((e) => {
        console.log(e);
      });
  }

    destroyItem($id) {
        if (!window.confirm('Are you sure you want to delete this listing?')) return;
        Api.delete('/api/manager/listing/' + $id)
            .then((response) => {
                this.fetchItems();
            })
            .catch((e) => {
                console.log(e);
            })
    }

    archiveItem($id) {
        if (!window.confirm('Are you sure you want to archive this listing?')) return;
        Api.post('/api/manager/listing/' + $id + '/archive')
            .then((response) => {
                this.fetchItems();
            })
            .catch((e) => {
                console.log(e);
            })
    }

    updateStatus(listing, $status) {
        if (listing.status !== 'active' && $status === 'active' && (!listing.minimum_nights || !listing.advance_notice_hours)) {
            alert('You must set the minimum nights and advance notice hours before activating this listing.');
            return;
        }
        if (listing.status === $status) return;
        if (!window.confirm('Are you sure you want to change the status of this listing?')) return;

        Api.post('/api/manager/listing/' + listing.id + '/status', {status: $status})
            .then((response) => {
                this.fetchItems();
            })
            .catch((e) => {
                console.log(e);
            })
    }


  formatAddress(listing) {
    if (!isObject(listing.address)) return 'N/A';
    return [listing.address.street1, listing.address.city, listing.address.state, listing.address.zip].join(', ');
  }

  render() {
    return <>
      <Container className="top-container">
        <h2 className="pull-left">Listings</h2>
        <ButtonGroup className="listings-status">
          <Button active={this.state.status === 'active'} variant="outline-primary" onClick={() => {
            this.setState({ status: 'active' }, () => this.fetchItems());
          }}>Active</Button>
          <Button active={this.state.status === 'inactive'} variant="outline-primary" onClick={() => {
            this.setState({ status: 'inactive' }, () => this.fetchItems());
          }}>Inactive</Button>
          <Button active={this.state.status === 'draft'} variant="outline-primary" onClick={() => {
            this.setState({ status: 'draft' }, () => this.fetchItems());
          }}>Drafts</Button>
          <Button active={this.state.status === 'all'} variant="outline-primary" onClick={() => {
            this.setState({ status: 'all' }, () => this.fetchItems());
          }}>All</Button>
        </ButtonGroup>

        <ButtonGroup className="listings-source">
          <Button active={this.state.source === 'internal'} variant="outline-primary" onClick={() => {
            this.setState({ source: 'internal' }, () => this.fetchItems());
          }}>Internal</Button>
          <Button active={this.state.source === 'guesty'} variant="outline-primary" onClick={() => {
            this.setState({ source: 'guesty' }, () => this.fetchItems());
          }}>Guesty</Button>
          <Button active={this.state.source === 'all'} variant="outline-primary" onClick={() => {
            this.setState({ source: 'all' }, () => this.fetchItems());
          }}>All</Button>
        </ButtonGroup>
        <Link to="/manager/listings/create" className="btn btn-primary fa-pull-right">+ Create</Link>
      </Container>
      <Container className="listings-table">


        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nickname</th>
              <th>Title</th>
              <th>Address</th>
              <th>Picture</th>
              <th></th>
              </tr>
          </thead>
          <tbody>
          {this.state.paginatedListings.data.map((listing, index) => (
            <tr key={index} className="listing-item">
              <td>{listing.nickname || 'N/A'}</td>
              <td>
                <div className="listing-name">
                  {listing.name || 'N/A'}
                  <div>
                    <span className={'status status-' + listing.status}>{listing.status ? listing.status : 'no status'}</span>
                    { listing.source && <span className={'source source-' + listing.source}>
                      {listing.source ? listing.source : ''}
                    </span> }
                  </div>
                </div>
              </td>
              <td>{this.formatAddress(listing) || 'N/A'}</td>
              <td>{
              listing.files && listing.files[0] &&
                <div className="listing-preview" style={{backgroundImage: `url(${listing.files[0].url})`}}></div>
              }</td>
              <td>

                <SplitButton
                    size="sm"
                    drop="down"
                    variant={listing.status === 'active' ? 'outline-primary' : (listing.status === 'inactive' ? 'outline-danger' : 'outline-warning')}
                    title={listing.status ? listing.status : 'no status'}
                    className="me-4"
                    >
                        {listing.status !== 'active' && <Dropdown.Item onClick={
                            () => this.updateStatus(listing, 'active')
                        }>Set as Active</Dropdown.Item>}

                        {listing.status !== 'inactive' && <Dropdown.Item onClick={
                            () => this.updateStatus(listing, 'inactive')
                        }>Set as Inactive</Dropdown.Item>}

                        {listing.status !== 'draft' && <Dropdown.Item onClick={
                            () => this.updateStatus(listing, 'draft')
                        }>Set as Draft</Dropdown.Item>}

                </SplitButton>

              {
                  listing.source !== 'guesty' &&
                  <ButtonGroup className="listing-actions" style={{ marginRight: '1em' }}>
                    <LinkWithTooltip
                        tooltip={(!listing.minimum_nights || !listing.advance_notice_hours) ? "Availability not set" : "Availability" }
                        id={'a-' + listing.id}
                        className="btn btn-sm btn-outline-light"
                        to={'/manager/listings/' + listing.id + '/availability' }

                    >
                        {
                            (!listing.minimum_nights || !listing.advance_notice_hours)
                            && <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" />
                        }
                        <FontAwesomeIcon icon={faClock} />
                    </LinkWithTooltip>
                    <LinkWithTooltip
                        tooltip="Pricing"
                        id={'p-' + listing.id}
                        className="btn btn-sm btn-outline-light"
                        to={'/manager/listings/' + listing.id + '/pricing' }
                    >
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </LinkWithTooltip>
                  </ButtonGroup>
                }
                {
                  listing.source !== 'guesty' &&
                  <ButtonGroup className="listing-actions">
                    <Link className="btn btn-sm btn-outline-primary" to={'/manager/listings/update/' + listing.id } >Edit</Link>
                    {
                        listing.reservations_count === 0
                        ? <Button size="sm" variant="outline-danger" onClick={() => this.destroyItem(listing.id)} >Delete</Button>
                        : <Button size="sm" variant="outline-warning" onClick={() => this.archiveItem(listing.id)} >Archive</Button>
                    }
                  </ButtonGroup>
                }
              </td>
            </tr>
          ))}
          </tbody>
          </table>

      </Container>

      <Container className="pagination">
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => {
            this.setState({ page: 1 }, () => this.fetchItems());
          }} />
          <Pagination.Prev disabled={this.state.page === 1} onClick={() => {
            this.setState({ page: this.state.page - 1 }, () =>  this.fetchItems());
          }} />

          {
            [...Array(this.state.paginatedListings.last_page).keys()].map((page) => {
              return <Pagination.Item key={page} active={page + 1 === this.state.page} onClick={() => {
                this.setState({ page: page + 1 }, () => this.fetchItems());
              }}>{page + 1}</Pagination.Item>
            })
          }

          <Pagination.Next disabled={this.state.page === this.state.paginatedListings.last_page} onClick={() => {
            this.setState({ page: this.state.page + 1 }, () => this.fetchItems());
          }} />
          <Pagination.Last onClick={() => {
            this.setState({ page: this.state.paginatedListings.last_page }, () => this.fetchItems());
          }} />
        </Pagination>
      </Container>
    </>
  }
}