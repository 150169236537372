import { isObject, isString } from "lodash";
import Api from "../../../Api";

const amenities = [
  'Air conditioning',
  'BBQ grill',
  'Cable TV',
  'Dishwasher',
  'Dryer',
  'Elevator',
  'Free parking on premises',
  'Heating',
  'Indoor fireplace',
  'Iron',
  'Refrigerator',
  'Stove',
  'TV',
  'Washer'
]

const additionalAmenities = [
  'Accessible-height bed',
  'Accessible-height toilet',
  'Baby bath',
  'Baby monitor',
  'Babysitter recommendations',
  'Bathtub',
  'Beach',
  'Beach essentials',
  'Beach front',
  'Beach view',
  'Bed linens',
  'Breakfast',
  'Carbon monoxide detector',
  'Changing table',
  'Children\'s book and toys',
  'Children\'s dinnerware',
  'Cleaning before checkout',
  'Cleaning disinfection',
  'Cofee maker',
  'Cookware',
  'Crib',
  'Desk',
  'Dishes and silverware',
  'Doorman',
  'Downtown',
  'Dryer in common space',
  'DVD player',
  'Emergency exit',
  'Enhanced cleaning practices',
  'EV charger',
  'Essentials',
  'Extra pillows and blankets',
  'Family/kid friendly',
  'Fire extinguisher',
  'Fireplace guards',
  'First aid kit',
  'Free parking on street',
  'Game console',
  'Garden or backyard',
  'Gold course front',
  'Golf view',
  'Gym',
  'Grab-rails for shower and toilet',
  'Hangers',
  'Hair dryer',
  'High chair',
  'High touch surfaces disinfected',
  'Hot tub',
  'Hot water',
  'Indoor pool',
  'Internet',
  'Kettle',
  'Kitchen',
  'Lake',
  'Lake access',
  'Lake front',
  'Laptop friendly workspace',
  'Long term stays allowed',
  'Luggage dropoff allowed',
  'Microwave',
  'Mountain',
  'Mountain view',
  'Oven',
  'Outdoor pool',
  'Outlet covers',
  'Pack \'n Play/travel crib',
  'Paid parking off premises',
  'Patio or balcony',
  'Pocket wifi',
  'Private entrance',
  'Private pool',
  'Resort',
  'River',
  'Roll-in shower with shower bench or chair',
  'Room-darkening shades',
  'Rural',
  'Shampoo',
  'Single level home',
  'Smoke detector',
  'Stair gates',
  'Stereo system',
  'Safe',
  'Sea view',
  'Ski in',
  'Ski in/Ski out',
  'Ski out',
  'Toaster',
  'Towels provided',
  'Town',
  'Communal pool',
  'Simming pool',
  'Table corner guards',
  'Tub with shower bench',
  'Village',
  'Washer in common space',
  'Water view',
  'Waterfront',
  'Wheelchair accessible',
  'Wide clearance to bed',
  'Wide clearance to shower and toilet',
  'Wide hallway clearance',
  'Window guards',
  'Wireless internet',
]

function handleInputChange(event) {
  const target = event.target;
  const value = target.value;
  const name = target.name;
  this.setState({ formData: { ...this.state.formData, [name]: value } });
}

function handleAmenityChange(event) {
  const target = event.target;
  const value = target.value;

  if (target.checked) {
      this.state.formData.amenities.push(value);
  } else {
      const index = this.state.formData.amenities.indexOf(value);
      if (index > -1) {
          this.state.formData.amenities.splice(index, 1);
      }
  }

  this.setState({ formData: { ...this.state.formData, amenities: this.state.formData.amenities } });
}

function readPicturesAsBase64(e) {
  const files = e.target.files;
  const allFiles = [];

  for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const component = this;

      reader.onload = () => {
          const fileInfo = {
              name: file.name,
              type: file.type,
              size: Math.round(file.size / 1000) + ' kB',
              base64: reader.result,
              file: file,
          };
          allFiles.push(fileInfo);

          if (allFiles.length === files.length) {
              component.setState({ images: allFiles, imagesUploading: true })
          }
      }
  }
}

function handlePictureDescriptionChange(e, picture) {
  const target = e.target;
  const value = target.value;

  const index = this.state.images.indexOf(picture);
  if (index > -1) {
      this.state.images[index].name = value;
  }

  this.setState({ images: this.state.images });
}

function handleAddressChange(event) {
  const target = event.target;
  const value = target.value;
  const name = target.name;

  this.setState({ formData: { ...this.state.formData, address: { ...this.state.formData.address, [name]: value } } });
}

function submitForm(e) {
  e.preventDefault();

  const amenities = this.state.formData.amenities.map(amenity => {
      return isString(amenity) ? amenity : amenity.name;
  })

  const payload = {
    ...this.state.formData,
    amenities: amenities,
    imagesUploading: this.state.imagesUploading,
  }

  if (this.state.imagesUploading) {
    payload.images = this.state.images.map(image => {
        return {
          data: image.base64,
          name: image.name,
        }
    })
  } else {
    payload.images = this.state.images.map(image => {
        return {
          id: image.id,
          name: image.name,
        }
    })
  }

  this.setState({ submitInProgress: true })

  Api.post('/api/manager/listing/' + this.state.formData.id, payload)
      .then(response => {
        this.setState({ listingSaved: true });
      }
    ).catch((error, response) => {
      console.log(error, response)
      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        const formErrors = {};
        Object.keys(errors).forEach(key => {
          formErrors[key] = errors[key][0];
        });
        this.setState({ formErrors: formErrors }, () => console.log(this.state.formErrors));
      }
    }
  );
}

function fetchListing() {
  Api.get('/api/manager/listing/' + this.props.id)
      .then(response => {
          if (response.data.id) {
              const images = response.data.files.map(image => {
                  return { src: image.url, name: image.name, id: image.id }
              })
              const amenities = response.data.amenities.map(amenity => {
                  return amenity.name
              })
              const address = isObject(response.data.address) ? response.data.address : {
                  street1: '',
                  street2: '',
                  city: '',
                  state: '',
                  postal_code: '',
                  country: '',
              };
              this.setState({ formData: { ...response.data, amenities: amenities, address: address}, images: images })
          } else {
              console.error('Error fetching listing')
          }
      }).catch(error => {
          console.error(error);
      })
}

function fetchAdderssComponents(type) {
  Api.get('/api/manager/address/' + type)
      .then(response => {
          if (response.data) {
              this.setState({ [type]: response.data })
          } else {
              console.error('Error fetching address components')
          }
      }
    ).catch(error => {
      console.error(error);
    }
  )
}

export {amenities, additionalAmenities, handleInputChange, handleAmenityChange, readPicturesAsBase64, handleAddressChange, submitForm, fetchListing, fetchAdderssComponents, handlePictureDescriptionChange};