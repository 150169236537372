import React, {Component} from 'react';
import {Badge, Button, Card, Col, Container, Offcanvas, Row} from "react-bootstrap";
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBathtub, faBed, faMapPin, faMoneyBill, faRulerCombined, faStar, faUsers
} from "@fortawesome/free-solid-svg-icons";
import ImageGallery from "react-image-gallery";
import {ModifyReservationModal} from "../components/Modals/ModifyReservationModal";

export class Account
    extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showReservationDetails:     false,
            showReservationDetailsData: [],
            showModifyReservationModal: false
        }

        this.toggleReservationDetails     = this.toggleReservationDetails.bind(this);
        this.toggleModifyReservationModal = this.toggleModifyReservationModal.bind(this);
    }


    toggleReservationDetails(event) {

        let newData = [];
        let id;

        const client_reservations = this.props.user.client_reservations;

        if (event && event.currentTarget) {
            console.log(event.currentTarget);
            id = event.currentTarget.getAttribute('data-id');
        }

        for (const key in
            client_reservations) {
            let thisRow = client_reservations[key];
            if (thisRow.id === id) {
                newData = thisRow;
            }
        }

        this.setState({
                          showReservationDetailsData: newData,
                          showReservationDetails:     !this.state.showReservationDetails
                      });

    }

    toggleModifyReservationModal(event) {
        this.setState({showModifyReservationModal: !this.state.showModifyReservationModal})
    }

    render() {

        const client_reservations = this.props.user.client_reservations;

        const reservationData = this.state.showReservationDetailsData;
        const images          = (
            reservationData.listing && reservationData.listing.files ? reservationData.listing.files.map((file) => {
                return {
                    original:  file.url,
                    thumbnail: file.url
                }
            }) : null
        )

        return (
            <>

                <ModifyReservationModal
                    toggleFunction={this.toggleModifyReservationModal}
                    show={this.state.showModifyReservationModal}
                    reservation={this.state.showReservationDetailsData}
                />

                <Offcanvas show={this.state.showReservationDetails} placement={"end"}
                           onHide={this.toggleReservationDetails}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Trip Details</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className={"checkout"}>
                        <h3>{(
                            reservationData.listing && reservationData.listing.tagline ? reservationData.listing.tagline : "Fontainebleau Resort"
                        )}</h3>

                        <FontAwesomeIcon icon={faMapPin}/> <span
                        className={"text-primary fs-5 ms-2"}>{reservationData.listing && reservationData.listing.address ? reservationData.listing.address.city + ", " + reservationData.listing.address.state : "N/A"}</span>


                        <div className="mb-4 mt-4">

                            {/*<img width={"100%"} src={cardImage} className={"room-image"}/>*/}
                            {images && <ImageGallery
                                items={images}/>}

                        </div>

                        <div
                            className={"room_badge"}>NEW
                        </div>

                        <h6>{reservationData.listing && reservationData.listing.name}</h6>

                        <Row className={"mb-4 fs-5 fw-light"}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}
                                 className={"d-flex justify-content-between"}>
                                <div className={"d-flex align-items-center"}>
                                    <FontAwesomeIcon icon={faUsers} className={"me-2"}/>
                                    <p className={" m-0"}> {reservationData.listing && reservationData.listing.number_of_guests}
                                        <small> guests</small></p>
                                </div>
                                <div className={"d-flex align-items-center"}>
                                    <FontAwesomeIcon icon={faRulerCombined} className={"me-2"}/>
                                    <p className={" m-0"}>{reservationData.listing && (reservationData.listing.square_feet ? reservationData.listing.square_feet.toLocaleString() : "N/A")}
                                        <small> sqft</small></p>
                                </div>
                                <div className={"d-flex align-items-center"}>
                                    <FontAwesomeIcon icon={faMoneyBill} className={"me-2"}/>
                                    <p className={" m-0"}>${(
                                        reservationData.reservation_charges && reservationData.reservation_charges[0] ? Math.round(
                                            reservationData.reservation_charges[0].total / reservationData.nights) : null
                                    )}<small> / nt</small>
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}
                                 className={"d-flex justify-content-between"}>
                                <div className={"d-flex align-items-center"}>
                                    <FontAwesomeIcon className={"me-2"} icon={faBed}/>
                                    <p className={" m-0"}>{reservationData.listing ? reservationData.listing.bedrooms : null}
                                        <small> room{(
                                            reservationData.listing && (
                                                reservationData.listing.bedrooms > 1 || reservationData.listing.bedrooms === "0"
                                            ) ? "s" : null
                                        )}</small>
                                    </p>
                                </div>
                                <div className={"d-flex align-items-center"}>
                                    <FontAwesomeIcon className={" me-2"} icon={faBathtub}/>
                                    <p className={"m-0"}>{reservationData.listing ? reservationData.listing.bathrooms : null}
                                        <small> bath{(
                                            reservationData.listing && (
                                                reservationData.listing.bathrooms > 1 || reservationData.listing.bathrooms === "0"
                                            ) ? "s" : null
                                        )}</small>
                                    </p>
                                </div>
                                <div className={"d-flex align-items-center"}>
                                    <FontAwesomeIcon icon={faStar} className={"me-2"}/><p
                                    className={"m-0"}> 5.00
                                    <small> (25)</small></p>
                                </div>
                            </Col>
                        </Row>


                        <div className={"dates mb-4"}>

                            <Col>
                                <small>CHECK IN</small>
                                <p style={{
                                    fontSize: "18px",
                                    color:    "white"
                                }}>
                                    <b>{moment(reservationData.check_in_date)
                                        .format('ddd')},</b> {moment(reservationData.check_in_date)
                                    .format('DD MMM YYYY')}
                                </p>
                                <p>4:00 PM</p>
                            </Col>

                            <Col>
                                <small>CHECK OUT</small>
                                <p style={{
                                    fontSize: "18px",
                                    color:    "white"
                                }}>
                                    <b>{moment(reservationData.check_out_date)
                                        .format('ddd')},</b> {moment(reservationData.check_out_date)
                                    .format('DD MMM YYYY')}
                                </p>
                                <p>11:00 AM</p>
                            </Col>

                        </div>

                        <h1 style={{
                            fontWeight:    700,
                            fontSize:      "21px",
                            letterSpacing: "2px",
                            color:         "white"
                        }}>PAYMENT <span
                            style={{fontWeight: 400}}>INFORMATION</span></h1>

                        <div className="pricing_table_wrapper">
                            <table>
                                <thead>
                                <tr>
                                    <td>
                                        {reservationData.nights} Night(s), {(
                                        reservationData.adults + reservationData.children
                                    )} Guest(s)
                                    </td>
                                    <td>
                                        amount
                                    </td>
                                </tr>
                                </thead>

                                <tbody>

                                {(
                                    reservationData.listing && reservationData.listing.pricing ? reservationData.listing.pricing.breakdown.map(
                                        (array) => {
                                            return (
                                                <tr>
                                                    <td>{moment(array.date)
                                                        .format("DD MMM")}</td>
                                                    <td>${array.price
                                                               .toLocaleString('en-US', {
                                                                   minimumFractionDigits: 2
                                                               })}</td>
                                                </tr>
                                            )
                                        }) : null
                                )}

                                <tr>
                                    <td className="darker">SUBTOTAL</td>
                                    <td className="darker">${reservationData.reservation_charges && reservationData.reservation_charges[0] ? reservationData.reservation_charges[0].subtotal.toLocaleString(
                                        'en-US', {
                                            minimumFractionDigits: 2
                                        }) : "N/A"}</td>
                                </tr>

                                <tr>
                                    <td className="darker">
                                        TAXES
                                    </td>
                                    <td className="darker">
                                        ${reservationData.reservation_charges && reservationData.reservation_charges[0] ? reservationData.reservation_charges[0].tax.toLocaleString(
                                        'en-US', {
                                            minimumFractionDigits: 2
                                        }) : "N/A"}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="darker">
                                        TOTAL
                                    </td>
                                    <td className="darker">
                                        ${reservationData.reservation_charges && reservationData.reservation_charges[0] ? reservationData.reservation_charges[0].total.toLocaleString(
                                        'en-US', {
                                            minimumFractionDigits: 2
                                        }) : "N/A"}
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>

                        <Row className={"my-4"}>
                            <Col>
                                <a target={"_blank"} href={reservationData.stripe_receipt_url}
                                   className={"btn btn-outline-light w-100"}>Download Receipt</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={this.toggleModifyReservationModal} className={"w-100"}
                                        variant={"outline-primary"}>Modify</Button>
                            </Col>
                            <Col>
                                <Button onClick={this.toggleModifyReservationModal} className={"w-100"}
                                        variant={"outline-danger"}>Cancel</Button>
                            </Col>
                        </Row>

                    </Offcanvas.Body>
                </Offcanvas>
                <Container>

                    <Row>
                        <Col>
                            <h1 className={"page-title"}>My Account</h1>
                        </Col>
                    </Row>

                    <h1 className={"mb-5 fs-4 text-secondary"}>My Trips</h1>

                    <h2 className={"mb-5 fs-3 fw-normal"}>Upcoming</h2>

                    <Row>
                        {client_reservations.length ? client_reservations.map((reservation) => {

                            var cardImage = (
                                reservation.listing.files.length ? reservation.listing.files[0].url : ""
                            );

                            return (
                                <Col xs={12} sm={12} md={12} lg={4}>
                                    <h4 className={"text-white"}>{reservation.listing.tagline ? reservation.listing.tagline : "Fontainebleau Resort"}</h4>
                                    <h5 className={"mb-4"}><FontAwesomeIcon className={"me-2"} icon={faMapPin}/> <span
                                        className={"text-primary"}>Miami Beach, FL</span></h5>
                                    <Card className={"tableCard mb-0"} style={{cursor: "pointer"}}
                                          onClick={this.toggleReservationDetails} data-id={reservation.id}>

                                        <div className="tableCardHeader"
                                             style={{background: "url('" + cardImage + "')"}}>
                                            <div>
                                                <div className={"d-none"}>
                                                    <Badge
                                                        className={"ms-2 coupon-badge"}>SAVE</Badge>
                                                </div>
                                                <div>
                                                    {reservation.listing.name}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"tableCardBody"}>
                                        
                                            <Row className={"dates"}>

                                                <Col>
                                                    <small>CHECK IN</small>
                                                    <p style={{
                                                        fontSize: "18px",
                                                        color:    "white"
                                                    }}>
                                                        <b>{moment(reservation.check_in_date)
                                                            .format('ddd')},</b> {moment(reservation.check_in_date)
                                                        .format('DD MMM YYYY')}
                                                    </p>
                                                    <p>4:00 PM</p>
                                                </Col>

                                                <Col>
                                                    <small>CHECK OUT</small>
                                                    <p style={{
                                                        fontSize: "18px",
                                                        color:    "white"
                                                    }}>
                                                        <b>{moment(reservation.ccheck_out_date)
                                                            .format('ddd')},</b> {moment(reservation.check_out_date)
                                                        .format('DD MMM YYYY')}
                                                    </p>
                                                    <p>11:00 AM</p>
                                                </Col>
                                            </Row>

                                        </div>

                                    </Card>
                                </Col>
                            )

                        }) : <Col>No reservations.</Col>}
                    </Row>

                    <h2 className={"my-5 fs-3 fw-normal"}>Past</h2>

                    <Row>
                        <Col>No reservations.</Col>
                    </Row>

                </Container>
            </>
        )
    }
}