import React, { Component } from 'react';
import './js/index';
import './js/style.scss';
import Api from '../../../Api';
import { getCookie, setCookie } from './js/dal/storage';
import moment from 'moment/moment';

export class Datepicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: this.props.data
        ? this.props.data
        : {
            city: '',
          },
      bookingFormElement: null,
      citiesACOpened: false,
      cities: [],
      filteredCities: [],
    };

    this.search = this.search.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.fetchCities = this.fetchCities.bind(this);
  }

  handleCityChange = (event) => {
    const city = event.target.value;
    const filteredCities = this.state.cities.filter((cityItem) => {
      return cityItem.city.toLowerCase().includes(city.toLowerCase());
    });
    this.setState({ formData: { ...this.state.formData, city: city }, filteredCities: filteredCities }, () => !this.state.citiesACOpened);
  };

  handleUpDown = (event) => {
    this.setState({ citiesACOpened: true });

    if (event.keyCode === 38) {
      event.preventDefault();
      const city = this.state.filteredCities[this.state.filteredCities.indexOf(this.state.formData.city) - 1];
      this.setState({ formData: { ...this.state.formData, city: city } });
    } else if (event.keyCode === 40) {
      event.preventDefault();
      const city = this.state.filteredCities[this.state.filteredCities.indexOf(this.state.formData.city) + 1];
      this.setState({ formData: { ...this.state.formData, city: city } });
    }

    if (event.keyCode === 13) {
      event.preventDefault();
      this.setState({ citiesACOpened: false });
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let { formData = [], formErrors = [], data = [] } = this.state;

    formData[name] = value;
    formErrors[name] = null;
    data[name] = value;

    this.setState({
      formData: formData,
      formErrors: formErrors,
      data: data,
    });

    this.props.handleInputChange(event);
  };

  isValidDate = (dateString) => {
    const format = 'YYYY-MM-DD';
    return moment(dateString, format, true).isValid();
  }

  getQueryParameters() {
    const queryParameters = new URLSearchParams(window.location.search);
    const checkIn = queryParameters.get('checkIn') || getCookie('searchForm-checkIn');
    const checkOut = queryParameters.get('checkOut') || getCookie('searchForm-checkOut');
    const adults = parseInt(queryParameters.get('adults') || getCookie('searchForm-adults') || 2);
    const children = parseInt(queryParameters.get('children') || getCookie('searchForm-children') || 0);
    const city = (queryParameters.get('city') || getCookie('searchForm-city') || '').replaceAll('"', '').replaceAll('\\', '');
    const isValidDate=(dateString)=> {
      const format = 'YYYY-MM-DD';
      const isValid = moment(dateString, format, true).isValid();
      return isValid;
    }

    let checkInDate = checkIn && this.isValidDate(checkIn) ? new Date(checkIn.replace(/-/g, '/')) : new Date(new Date().setDate(new Date().getDate() + 14));

    let checkOutDate;
    if (this.props.isMonth) {
      checkOutDate = new Date(checkInDate);
      checkOutDate.setMonth(checkOutDate.getMonth() + 1);
    } else {
      checkOutDate = checkOut && this.isValidDate(checkOut) ? new Date(checkOut.replace(/-/g, '/')) : new Date(new Date().setDate(new Date().getDate() + 17));
    }

    const checkInOut = {
      from: checkInDate,
      to: checkOutDate,
      onChange: (range) => {},
    };

    return { checkIn, checkOut, checkInOut, adults, children, city };
  }

  componentDidMount() {
    const { checkIn, checkInOut, adults, children, city } = this.getQueryParameters();

    if (checkIn) {
      document.cookie = 'jso-booking-form-1=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.makrealty.com';
    }

    const $form = window.jso.bookingForm('#search_form', {
      checkInOut: checkInOut,
      animation: 'fade',
      redirectAfterSubmit: false,
      storageType: 'cookies',
      storageName: 'jso-booking-form-1',
      cookiesExpiration: 43200,
      countriesAutocomplete: {
        dataURL: '/wp-content/plugins/nd-booking/cities.json',
      },
      guestsSelector: {
        fields: {
          adults: {
            type: 'buttons', // buttons, select
            quantity: adults,
          },

          children: {
            type: 'buttons', // buttons, select
            quantity: children,
          },
          age: null,
          rooms: null,
        },
        onChange: (guests) => {},
      },
    });

    this.setState({
      bookingFormElement: $form,
    });

    this.props.updateBookingFormEl($form, city);
    this.fetchCities();
  }

  fetchCities() {
    const { checkInOut, city } = this.getQueryParameters();

    const cities = [];
    Api.get(
      '/api/address/cities?checkIn=' +
        checkInOut.from.toISOString().split('T')[0] +
        '&checkOut=' +
        checkInOut.to.toISOString().split('T')[0],
    )
      .then((response) => {
        for (let state in response.data) {
          cities.push({ city: response.data[state], state });
        }

        this.setState({ cities: cities, filteredCities: cities, formData: { ...this.state.formData, city: city } });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  search() {
    let formValue = this.state.bookingFormElement.bookingForm.getValue();
    formValue.push({ name: 'city', value: this.state.formData.city });
    this.props.search(formValue);
    this.saveFormData(formValue);
    this.fetchCities();
  }

  saveFormData(formValues) {
    setCookie('searchForm-checkIn', formValues[0].value, 1);
    setCookie('searchForm-checkOut', formValues[1].value, 1);
    setCookie('searchForm-adults', formValues[2].value, 1);
    setCookie('searchForm-children', formValues[3].value, 1);
    setCookie('searchForm-city', formValues[4].value || '', 1);
  }

  render() {
    return (
      <>
        <div className="mk-design search_form_wrapper">
          <form
            method="post"
            action="#"
            id="search_form"
            autoComplete="off"
            className="mk-search jso-booking-form jso-booking-form-separator jso-booking-form-flat jso-booking-form-colored jso-booking-form-horizontal"
          >
            <div className="jso-booking-form-content">
              <div className="jso-booking-check-in-out" tabIndex="0">
                <h4 className="jso-booking-form-title">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                    <rect fill="#bfbfbf" x="272" y="208" width="48" height="48" rx="4" ry="4" />
                    <rect fill="#bfbfbf" x="352" y="208" width="48" height="48" rx="4" ry="4" />
                    <rect fill="#bfbfbf" x="272" y="288" width="48" height="48" rx="4" ry="4" />
                    <rect fill="#bfbfbf" x="352" y="288" width="48" height="48" rx="4" ry="4" />
                    <rect fill="#bfbfbf" x="112" y="288" width="48" height="48" rx="4" ry="4" />
                    <rect fill="#bfbfbf" x="192" y="288" width="48" height="48" rx="4" ry="4" />
                    <rect fill="#bfbfbf" x="112" y="368" width="48" height="48" rx="4" ry="4" />
                    <rect fill="#bfbfbf" x="192" y="368" width="48" height="48" rx="4" ry="4" />
                    <rect fill="#bfbfbf" x="272" y="368" width="48" height="48" rx="4" ry="4" />
                    <path
                      fill="#bfbfbf"
                      d="M448,64H400V32H360V64H152V32H112V64H64A32,32,0,0,0,32,96V448a32,32,0,0,0,32,32H448a32,32,0,0,0,32-32V96A32,32,0,0,0,448,64ZM436,436H76V176H436Z"
                    />
                  </svg>
                  {this.props.isMonth ? "Move-In" : "Dates"}
                </h4>

                <div className="jso-booking-check-in-out-content">
                  <div className="jso-date-range-picker-text">{this.props.isMonth ? "Select Move-In-Date" : "Check In"}</div>
                  {!this.props.isMonth && <div className="jso-date-range-picker-text">Check Out</div>}
                </div>
              </div>

              <div className="jso-booking-form-guests" tabIndex="0">
                <h4 className="jso-booking-form-title">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                    <path
                      fill="#bfbfbf"
                      d="M432,230.7a79.44,79.44,0,0,0-32-6.7H112a79.51,79.51,0,0,0-32,6.69h0A80.09,80.09,0,0,0,32,304V416a16,16,0,0,0,32,0v-8a8.1,8.1,0,0,1,8-8H440a8.1,8.1,0,0,1,8,8v8a16,16,0,0,0,32,0V304A80.09,80.09,0,0,0,432,230.7Z"
                    />
                    <path
                      fill="#bfbfbf"
                      d="M376,80H136a56,56,0,0,0-56,56v72a4,4,0,0,0,5.11,3.84A95.5,95.5,0,0,1,112,208h4.23a4,4,0,0,0,4-3.55A32,32,0,0,1,152,176h56a32,32,0,0,1,31.8,28.45,4,4,0,0,0,4,3.55h24.46a4,4,0,0,0,4-3.55A32,32,0,0,1,304,176h56a32,32,0,0,1,31.8,28.45,4,4,0,0,0,4,3.55H400a95.51,95.51,0,0,1,26.89,3.85A4,4,0,0,0,432,208V136A56,56,0,0,0,376,80Z"
                    />
                  </svg>
                  Travelers
                </h4>

                <div className="jso-quantity-selector-fields" />
              </div>

              <button type="submit" className="jso-booking-form-search-btn" onClick={this.search}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 512 512">
                  <path
                    d="M221.09,64A157.09,157.09,0,1,0,378.18,221.09,157.1,157.1,0,0,0,221.09,64Z"
                    style={{
                      fill: 'none',
                      stroke: '#fff',
                      strokeMiterLimit: 10,
                      strokeWidth: '32px',
                    }}
                  />
                  <line
                    x1="338.29"
                    y1="338.29"
                    x2="448"
                    y2="448"
                    style={{
                      fill: 'none',
                      stroke: '#fff',
                      strokeLinecap: 'round',
                      strokeMiterLimit: 10,
                      strokeWidth: '32px',
                    }}
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}
