import React from 'react';
import failed from '../../../assets/images/failed.svg';

export default function FailedCheckout() {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className="result-page-wrapper">
      <div className="result-page">
        <div className="result-message">
          <img src={failed} alt=''/>
          <p className="result-top-text">Failed Payment</p>
          <p className="result-bot-text">Unfortunately, the reservation failed. Please try again</p>
          <button className="try-again-btn" onClick={handleReload}>
            TRY AGAIN
          </button>
        </div>
      </div>
    </div>
  );
}
