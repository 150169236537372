import React, {useEffect} from 'react';

const GoogleTranslate = () => {
    useEffect(() => {
        // Define the Google Translate Init function
        window.googleTranslateElementInit = function () {
            new window.google.translate.TranslateElement({
                                                             pageLanguage: 'en',
                                                             layout:       window.google.translate.TranslateElement.InlineLayout.SIMPLE
                                                         }, 'google_translate_element');
        };

        // Load the Google Translate script
        const googleTranslateScript = document.createElement('script');
        googleTranslateScript.type  = 'text/javascript';
        googleTranslateScript.src   = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        document.body.appendChild(googleTranslateScript);
    }, []);

    return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
