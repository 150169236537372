import React, { useState } from 'react';
import './NewHeader.scss';

export default function NewHeader() {
  const [navbarOpen, setnavbarOpen] = useState(false);

  return (
    <header>
      <div className="new-header">
        <nav className="header-navbar header-item">
          <a className="header-navbar-item" href={'https://vacations.makrealty.com/'}>
            Home
          </a>
          <a className="header-navbar-item" href={'https://vacations.makrealty.com/list-your-property'}>
            List Your Property
          </a>
          <a className="header-navbar-item" href={'https://vacations.makrealty.com/concierge'}>
            Concierge
          </a>
          <a className="header-navbar-item" href={'https://vacations.makrealty.com/blog'}>
            Blog
          </a>
          <a className="header-navbar-item" href={'https://vacations.makrealty.com/contact'}>
            Contact
          </a>
        </nav>
        <div className="header-logo-container">
          <a href="https://vacations.makrealty.com">
            <img
              loading="lazy"
              width="184"
              height="60"
              src="https://vacations.makrealty.com/wp-content/uploads/2024/07/MAK-Logo-Black.svg"
              alt="MAK - Logo White"
            />
          </a>
        </div>
        <div className="header-user-section-container header-item">
          <a href="tel:%20+13052046049">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M20.0036 14.8837V18.6839C20.0036 18.993 19.9234 19.2257 19.6908 19.4584C19.4542 19.695 19.2291 19.7674 18.92 19.7674C14.3377 19.6873 8.99612 18.2298 5.50504 14.7388C2.01396 11.2477 0.548843 5.89844 0.46875 1.31614C0.46875 1.0071 0.545034 0.778153 0.777792 0.545492C1.01055 0.312734 1.2394 0.232544 1.55235 0.232544H5.35247L6.32922 6.09301L4.50544 8.46239C5.04724 9.99998 6.13465 11.633 7.37082 12.873C8.61079 14.1131 10.2362 15.1889 11.77 15.7307L14.1394 13.907L20.0036 14.8837Z"
                  fill="#1B2540"
                ></path>
              </svg>{' '}
            </span>
            <span className="phone-text">(305)-204-6049</span>
          </a>
          <div data-id="30bb58e" data-element_type="widget" data-widget_type="icon.default">
            <div>
              <div>
                <a href="https://vacations.book.makrealty.com/login">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path
                      d="M16.6796 19.7579C21.0214 19.7467 24.7132 21.7403 26.0701 26.0316C23.3349 27.6989 20.1154 28.3412 16.6796 28.3328C13.2437 28.3412 10.0242 27.6989 7.28906 26.0316C8.6476 21.7356 12.3331 19.7467 16.6796 19.7579Z"
                      fill="#1B2540"
                    ></path>
                    <path
                      d="M22.5282 9.55948C22.5282 12.8141 19.8898 15.4524 16.6351 15.4524C13.3806 15.4524 10.7422 12.8141 10.7422 9.55948C10.7422 6.30488 13.3806 3.6665 16.6351 3.6665C19.8898 3.6665 22.5282 6.30488 22.5282 9.55948Z"
                      fill="#1B2540"
                    ></path>
                  </svg>{' '}
                </a>
              </div>
            </div>
          </div>
          <a className="header-book-now-btn" href="https://vacations.book.makrealty.com/">
            <span>
              <span>Book Now</span>
            </span>
          </a>
        </div>
        <button
          type="button"
          className="menuToggle"
          onClick={() => {
            setnavbarOpen(!navbarOpen);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      {navbarOpen && (
        <div className="mobile-menu">
          <nav className="mobile-navbar">
            <a className="mobile-navbar-item" href={'https://vacations.makrealty.com/'}>
              Home
            </a>
            <a className="mobile-navbar-item" href={'https://vacations.makrealty.com/list-your-property'}>
              List Your Property
            </a>
            <a className="mobile-navbar-item" href={'https://vacations.makrealty.com/concierge'}>
              Concierge
            </a>
            <a className="mobile-navbar-item" href={'https://vacations.makrealty.com/blog'}>
              Blog
            </a>
            <a className="mobile-navbar-item" href={'https://vacations.makrealty.com/contact'}>
              Contact
            </a>
            <a className="mobile-navbar-item" href="tel:%20+13052046049">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M20.0036 14.8837V18.6839C20.0036 18.993 19.9234 19.2257 19.6908 19.4584C19.4542 19.695 19.2291 19.7674 18.92 19.7674C14.3377 19.6873 8.99612 18.2298 5.50504 14.7388C2.01396 11.2477 0.548843 5.89844 0.46875 1.31614C0.46875 1.0071 0.545034 0.778153 0.777792 0.545492C1.01055 0.312734 1.2394 0.232544 1.55235 0.232544H5.35247L6.32922 6.09301L4.50544 8.46239C5.04724 9.99998 6.13465 11.633 7.37082 12.873C8.61079 14.1131 10.2362 15.1889 11.77 15.7307L14.1394 13.907L20.0036 14.8837Z"
                    fill="#1B2540"
                  ></path>
                </svg>{' '}
              </span>
              <span className="phone-text">(305)-204-6049</span>
            </a>
            <a className="mobile-navbar-item mobile-book-btn" href="https://vacations.book.makrealty.com/">
              Book Now
            </a>
          </nav>
        </div>
      )}
    </header>
  );
}
