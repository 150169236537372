import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { EXTRAS } from '../constants';

const Extras = ({ isMobile }) => {

    return (
        <div className='extras' id='extras'>
            <div className='extras-textbox'>
                <p>Beyond the wildest Expectations</p>
                <h2>Exclusive</h2>
                <h2>Complimentary Extras</h2>
            </div>
            {!isMobile ? (
                <div className='card-wrapper'>
                    {EXTRAS.map(({ svg, text }) => (
                        <div className='card' key={text}>
                            <div className='card-icon' style={{ width: '100%' }}>{svg}</div>
                            <p dangerouslySetInnerHTML={{ __html: text }} />
                        </div>
                    ))}
                </div>
            ) : (
                <Carousel controls={false} style={{ marginTop: '50px', width: '100%' }} className='carouselExtras'>
                    {EXTRAS.map(({ svg, text }, index) => (
                        <Carousel.Item
                            style={{ height: '450px', }} key={index}>
                            <div
                                key={text}
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '10px',
                                    height: '375px'
                                }}
                            >
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: "350px"
                                }}>{svg}</div>
                                <p style={{
                                    width: '190px',
                                    height: '100px',
                                    textAlign: 'center',
                                    fontFamily: "Cormorant Garamond",
                                    fontSize: '28px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '90%',
                                    letterSpacing: '-0.84px',
                                }} dangerouslySetInnerHTML={{ __html: text }} />
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            )}
        </div>
    );
};

export default Extras;
