import React, { Component } from 'react';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import { ReactComponent as CloseButton } from '../../assets/icons/close.svg';
import './NewListing.scss';
import debounce from 'lodash/debounce';
import { DatePickerAndGuest } from '../../components/Forms/Datepicker/DatepickerAndGuest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSoap,
  faAirFreshener,
  faBabyCarriage,
  faBath,
  faBathtub,
  faBed,
  faBell,
  faBowlFood,
  faCartPlus,
  faChildren,
  faDog,
  faDoorOpen,
  faDumbbell,
  faEarthOceania,
  faElevator,
  faFaucet,
  faFireBurner,
  faFireExtinguisher,
  faFirstAid,
  faGlobe,
  faSpa,
  faHotTub,
  faIceCream,
  faLock,
  faMusic,
  faParking,
  faPersonBiking,
  faShare,
  faSmoking,
  faSwimmingPool,
  faTelevision,
  faTShirt,
  faUmbrellaBeach,
  faUtensils,
  faUtensilSpoon,
  faWarning,
  faWind,
  faCalendarCheck,
  faArrowUpFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment/moment';
import { toast } from 'react-hot-toast';
import Api from '../../Api';
import ListingMap from './ListingMap';
import { isObject } from 'lodash';
import StarRating from '../../components/Rating/NewStarRating';
import { Loader } from '../../components/Loader/Loader';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

import {ReactComponent as  BeachAccess} from '../../assets/icons/BeachAccess.svg';
import {ReactComponent as PaidParging} from '../../assets/icons/PaidParging.svg';
import {ReactComponent as Fridge} from '../../assets/icons/Fridge.svg';
import {ReactComponent as OutdoorKitchen} from '../../assets/icons/OutdoorKitchen.svg';
import {ReactComponent as ShowerGel} from '../../assets/icons/ShowerGel.svg';
import {ReactComponent as Coffee} from '../../assets/icons/Cofee.svg';
import {ReactComponent as CofeeMaker} from "../../assets/icons/CofeeMaker.svg"
import {ReactComponent as UserIcon} from "../../assets/icons/userListingPage.svg";
import {ReactComponent as RulerIcon} from "../../assets/icons/rulerListingPage.svg"
import {ReactComponent as MoneIcon} from "../../assets/icons/moneyListingPage.svg"
import {ReactComponent as BathIcon} from "../../assets/icons/bathListingPage.svg"
import {ReactComponent as BedIcon} from "../../assets/icons/bedListingPage.svg"

import location from "../../assets/icons/location.svg"
import { Helmet } from 'react-helmet-async';

import { extractSquareFeet, formatPrice } from '../../utils';

const iconArray = {
  beach: {isFontAwesomeicon: false, icon: <BeachAccess />},
  bicycles_available: {isFontAwesomeicon: true, icon: faPersonBiking},
  doorman: {isFontAwesomeicon: true, icon: faDoorOpen},
  smoking_allowed: {isFontAwesomeicon: true, icon: faSmoking},
  coffee_maker: {isFontAwesomeicon: false, icon: <CofeeMaker />},
  crib: {isFontAwesomeicon: true, icon: faBabyCarriage},
  cable_tv: {isFontAwesomeicon: true, icon: faTelevision},
  refrigerator: {isFontAwesomeicon: true, icon: faIceCream},
  towels_provided: {isFontAwesomeicon: true, icon: faBath},
  hot_tub: {isFontAwesomeicon: true, icon: faHotTub},
  fire_extinguisher: {isFontAwesomeicon: true, icon: faFireExtinguisher},
  free_parking_on_premises: {isFontAwesomeicon: true, icon: faParking},
  resort: {isFontAwesomeicon: true, icon: faSpa},
  carbon_monoxide_detector: {isFontAwesomeicon: true, icon: faWarning},
  pets_allowed: {isFontAwesomeicon: true, icon: faDog},
  bathtub: {isFontAwesomeicon: true, icon: faBathtub},
  air_conditioning: {isFontAwesomeicon: true, icon: faAirFreshener},
  near_ocean: {isFontAwesomeicon: true, icon: faEarthOceania},
  smoke_detector: {isFontAwesomeicon: true, icon: faSmoking},
  iron: {isFontAwesomeicon: true, icon: faTShirt},
  elevator: {isFontAwesomeicon: true, icon: faElevator},
  safe: {isFontAwesomeicon: true, icon: faLock},
  wireless_internet: {isFontAwesomeicon: true, icon: faGlobe},
  shampoo: {isFontAwesomeicon: true, icon: faSoap},
  tv: {isFontAwesomeicon: true, icon: faTelevision},
  hair_dryer: {isFontAwesomeicon: true, icon: faWind},
  desk: {isFontAwesomeicon: true, icon: faBell},
  swimming_pool: {isFontAwesomeicon: true, icon: faSwimmingPool},
  microwave: {isFontAwesomeicon: true, icon: faBowlFood},
  stereo_system: {isFontAwesomeicon: true, icon: faMusic},
  gym: {isFontAwesomeicon: true, icon: faDumbbell},
  bed_linens: {isFontAwesomeicon: true, icon: faBed},
  cookware: {isFontAwesomeicon: true, icon: faUtensilSpoon},
  washer: {isFontAwesomeicon: true, icon: faFaucet},
  dryer: {isFontAwesomeicon: true, icon: faWind},
  oven: {isFontAwesomeicon: true, icon: faUtensils},
  stove: {isFontAwesomeicon: true, icon: faFireBurner},
  dishwasher: {isFontAwesomeicon: true, icon: faFaucet},
  'family/kid_friendly': {isFontAwesomeicon: true, icon: faChildren},
  beach_view: {isFontAwesomeicon: true, icon: faUmbrellaBeach},
  first_aid_kit: {isFontAwesomeicon: true, icon: faFirstAid},
  coffee: {isFontAwesomeicon: false, icon: <Coffee />},
  long_term_stays_allowed: {isFontAwesomeicon: true, icon: faCalendarCheck},
  paid_parking: {isFontAwesomeicon: false, icon: <PaidParging />},
  beach_access: {isFontAwesomeicon: true, icon: faUmbrellaBeach},
  mini_fridge: {isFontAwesomeicon: false, icon: <Fridge />},
  shower_gel: {isFontAwesomeicon: false, icon: <ShowerGel />},
  outdoor_kitchen: {isFontAwesomeicon: false, icon: <OutdoorKitchen />},
};

const skip_amenities_array = [
  'Hot water',
  'Internet',
  'Essentials',
  'Heating',
  'Kitchen',
  'Suitable for children (2-12 years)',
  'Suitable for infants (under 2 years)',
  'Suitable for events',
  'Beach essentials',
  'Dishes and silverware',
  'Crib',
  'Extra pillows and blankets',
  'Hangers',
  'Laptop friendly workspace',
  'Patio or balcony',
  'Cable TV',
];

export class Listing extends Component {
  constructor(props) {
    super(props);

    this.priceBreakdownRef = React.createRef();
    this.parentBreakdownRef = React.createRef();
    this.screenRef = React.createRef();
    this.accordionRefs = [];
    for (let i = 0; i <= 6; i++) {
      this.accordionRefs.push(React.createRef());
    }
    this.state = {
      listing: {
        address: {
          coordinates: {},
        },
      },
      show: '',
      adults: 0,
      children: 0,
      events: [],
      bookingFormEl: null,
      reservations: [],
      bookDisabled: false,
      isAccordionOpen: false,
      thumbnailPosition: 'right',
    };

    this.renderCustomControls = this.renderCustomControls.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.fetchListing = this.fetchListing.bind(this);
    this.fetchEvents = this.fetchEvents.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSearch = debounce(this.handleSearch.bind(this), 500);
    this.updateBookingFormEl = this.updateBookingFormEl.bind(this);
    this.toggleAccordion = this.toggleAccordion.bind(this);
    this.checkReservationConflict = this.checkReservationConflict.bind(this);
  }

  toggleAccordion() {
    this.setState((prevState) => ({
      isAccordionOpen: !prevState.isAccordionOpen,
    }));
  }

  checkReservationConflict() {
    const queryParams = new URLSearchParams(window.location.search);
    let checkIn = queryParams.get('checkIn');
    let checkOut = queryParams.get('checkOut');

    let selectedCheckIn = new Date(checkIn);
    let selectedCheckOut = new Date(checkOut);

    const conflict = this.state.reservations.some((reservation) => {
      let reservationCheckIn = new Date(reservation.check_in_date);
      let reservationCheckOut = new Date(reservation.check_out_date);

      return selectedCheckIn < reservationCheckOut && selectedCheckOut > reservationCheckIn && reservation.status === 'confirmed';
    });

    this.setState({ bookDisabled: conflict });
    conflict && toast.error('Selected days are already reserved');
  }

  updateBookingFormEl(el) {
    this.setState({
      bookingFormEl: el,
    });
    const fields = el.bookingForm.getValue();
    this.fetchListing(fields);
    this.fetchEvents();
  }

  handleSearch(event) {
    let value = event.target.value;
    this.setState({ search: value }, () => {
      this.fetchListing();
      this.fetchEvents();
    });
  }

  handleScroll() {
    const { top } = this.priceBreakdownRef?.current?.getBoundingClientRect();
    const { bottom } = this.parentBreakdownRef?.current?.getBoundingClientRect();
    const offset = 80;
    const parentTop = 1300;

    if (top <= offset) {
      this.priceBreakdownRef.current.classList.add('fixed');
    } else {
      this.priceBreakdownRef.current.classList.remove('fixed');
    }

    if (bottom <= 850) {
      this.priceBreakdownRef.current.classList.remove('fixed');
      this.parentBreakdownRef.current.classList.add('parent_container');
    } else {
      this.parentBreakdownRef.current.classList.remove('parent_container');
    }
    if (Math.abs(document.getElementById('root').getBoundingClientRect().top) < parentTop) {
      this.priceBreakdownRef.current.classList.remove('fixed');
    }
  }

  updateThumbnailPosition = () => {
    if (window.innerWidth <= 1399) {
      this.setState({ thumbnailPosition: 'bottom' });
    } else {
      this.setState({ thumbnailPosition: 'right' });
    }
  };

  componentDidMount() {
    this.fetchListing();
    this.checkReservationConflict();
    const { location } = window;
    const params = new URLSearchParams(location.search);
    if (!params.has('children') || params.get('children') === 'null') {
      params.set('children', '0');
      const updatedSearch = params.toString();
      const updatedUrl = `${location.pathname}?${updatedSearch}`;
      window.history.replaceState(null, '', updatedUrl);
    }
    window.addEventListener('scroll', this.handleScroll);
    this.updateThumbnailPosition();
    window.addEventListener('resize', this.updateThumbnailPosition);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateThumbnailPosition);
  }

  fetchListing(inputs) {
    let queryParameters = new URLSearchParams(window.location.search);
    let checkIn = queryParameters.get('checkIn');
    let checkOut = queryParameters.get('checkOut');
    let adults = queryParameters.get('adults');
    let children = queryParameters.get('children');
    let axiosUrl;

    if (!inputs) {
      axiosUrl =
        'api/listings/' +
        this.props.listingSlug +
        '?include=pricing,amenities&checkIn=' +
        checkIn +
        '&checkOut=' +
        checkOut +
        '&adults=' +
        adults +
        '&children=' +
        children;

      Api.get(
        'api/listings/' +
          this.props.listingSlug +
          '?include=pricing,amenities&checkIn=' +
          checkIn +
          '&checkOut=' +
          checkOut +
          '&adults=' +
          adults +
          '&children=' +
          children,
      )
        .then((res) => {
          const listingId = res.data.id;
          this.setState({
            listing: res.data,
            checkIn,
            checkOut,
            adults,
            children,
            reservations: [...res.data.reservations],
          },
          () => {
            this.fetchEvents(listingId)
          });
        })
        .catch((e) => console.log(e));
    } else {
      axiosUrl = 'api/listings/' + this.props.listingSlug + '?include=pricing,amenities';
      this.setState({ loading: true });
      for (let i = 0; i < inputs.length; i++) {
        let value = inputs[i].value;
        if (inputs[i].name === 'from_date') {
          checkIn = value;
          axiosUrl += '&checkIn=' + checkIn;
        } else if (inputs[i].name === 'to_date') {
          checkOut = value;
          axiosUrl += '&checkOut=' + checkOut;
        } else if (inputs[i].name === 'adults') {
          adults = value;
          axiosUrl += '&adults=' + adults;
        } else if (inputs[i].name === 'children') {
          children = value;
          axiosUrl += '&children=' + children;
        }
      }
      window.history.replaceState(
        null,
        null,
        '?checkIn=' + checkIn + '&checkOut=' + checkOut + '&adults=' + adults + '&children=' + children,
      );
      Api.get(axiosUrl)
        .then((res) => {
          this.setState({
            loading: false,
            listing: res.data,
            checkIn,
            checkOut,
            adults,
            children,
            reservations: [...res.data.reservations],
          });
        })
        .catch((e) => console.log(e));
    }
    this.checkReservationConflict();
  }

  getToday() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  getTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
  }

  formatDate(date) {
    let month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  fetchEvents(listingId) {
    const queryParameters = new URLSearchParams(window.location.search);

    let checkIn =
      queryParameters.get('checkIn') && queryParameters.get('checkIn') !== 'NaN-NaN-NaN'
        ? new Date(queryParameters.get('checkIn'))
        : this.getToday();
    let checkOut =
      queryParameters.get('checkOut') && queryParameters.get('checkOut') !== 'NaN-NaN-NaN'
        ? new Date(queryParameters.get('checkOut'))
        : this.getTomorrow();

    checkIn = this.formatDate(checkIn);
    checkOut = this.formatDate(checkOut);
    //const listingId = this.props.listingSlug.split('-').slice(-5).join('-');
    Api.get('api/listings/' + listingId + '/events?checkIn=' + checkIn + '&checkOut=' + checkOut)
      .then((res) => {
        setTimeout(() => {
          this.setState({ events: res.data.events });
        }, 3000);
      })
      .catch((e) => console.log(e));
  }

  share() {
    navigator.clipboard.writeText(window.location.href);
    toast.success('Link copied, now you can paste it.');
  }

  toggleFullscreen = () => {
    this.screenRef.current.toggleFullScreen();
  };

  renderCustomControls() {
    if (this?.screenRef?.current?.state?.isFullscreen) {
      return (
        <button className="custom-X-button" onClick={this.toggleFullscreen}>
          <CloseButton />
        </button>
      );
    }
    return null;
  }

  advanceNoticePassed = () => {
    const checkInDate = new Date(this.state.checkIn);
    checkInDate.setHours(checkInDate.getHours() - this.state.listing.advance_notice_hours);
    return checkInDate < new Date();
  };

  handleAccordionClick(index) {
    const targetRef = this.accordionRefs[index]?.current;
  
    const currentScrollY = window.scrollY;
  
    if (targetRef) {
      const observer = new MutationObserver(() => {
        window.scrollTo(0, currentScrollY);
  
        this.scrollToAccordionItem(index);
  
        observer.disconnect();
      });
  
      observer.observe(targetRef, { attributes: true, childList: true, subtree: true });
  
      setTimeout(() => {
        window.scrollTo(0, currentScrollY);
        this.scrollToAccordionItem(index);
      }, 300);
    }
  }  
  
  scrollToAccordionItem(index) {
    const targetRef = this.accordionRefs[index]?.current;
  
    if (targetRef) {
      targetRef.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  render() {
    const { user } = this.props;
    const listing = this.state.listing;
    const images = listing.files
      ? listing.files.map((file) => {
          return {
            original: file.url,
            thumbnail: file.url,
            originalAlt: file.name,
            thumbnailAlt: file.name,
            originalTitle: file.name,
            thumbnailTitle: file.name,
          };
        })
      : null;
    const guests = this.state.adults ? parseInt(this.state.adults) + parseInt(this.state.children) : 0;
    if (!listing.id) {
      return <Loader />;
    }

    const squareFeet = extractSquareFeet(listing.square_feet, listing.description);
    
    return (
      <div className="listing-page">
        {listing.id && <Helmet>
          <title>{listing.tagline || 'Fontainebleau Resort'} - {listing.name}</title>
          <meta name="description"
                content={`Explore ${listing.tagline || listing.name} in ${listing.address?.city || 'N/A'}, offering ${listing.amenities?.length || 'several'} top-notch amenities.`} />
          <meta name="keywords"
                content={`${listing.tagline}, ${listing.name}, ${listing.address?.city}, ${listing.address?.state}, ${listing.room_type}`} />
          <meta property="og:title" content={`${listing.tagline} - ${listing.name}`} />
          <meta property="og:description"
                content={`Stay at ${listing.name} in ${listing.address?.city || 'N/A'}. Enjoy premium amenities and a memorable experience.`} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        }

        <div className="breadcrumbs">
        <Breadcrumbs
            children={[
              {
                text: 'Search Listing',
                url: '/',
              },
              {
                text: 'Fontainebleau Resort',
                url: '/',
              },
            ]}
          />
        </div>
        <Row className='new-listing-hotel-info'>
          <Col className={'fs-4 d-flex'} sm="12" lg="7">
            <div className={'text-left'}>
              <div className={'text-white mb-2'}>
                <div className={'d-none d-sm-inline'}>
                  <StarRating rating={isObject(listing.rating) ? listing.rating.avg : 5} starSize={'20px'} className="star-rating" />
                </div>
                <h1 className="resort">{listing.tagline ? listing.tagline : 'Fontainebleau Resort'}</h1>
              </div>
              <h2 className={'listing-title'}>
                {listing.name}{' '}
                {user && (user.email === 'eddy@beetechy.com' || user.email === 'mk@makrealty.com') ? (
                  <small>{listing.nickname}</small>
                ) : (
                  <></>
                )}
              </h2>
              <Row className="mt-3">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={'pe-0 mb-3 mb-md-0 d-flex align-items-center fs-5 location-title'}>
                  <img src={location} alt="location" />
                  <div className={'ms-3'}>
                    <h5 className={'mb-1'}>
                      {listing.address ? listing.address.city + ', ' + listing.address.state : 'N/A'}
                      <small className={'fw-normal m-0 p-0'}>{listing.room_type}</small>
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>

            <div className={'text-right'}></div>
          </Col>
          <Col sm="12" lg="5" className='icon-boxes'>
            <Row className={'mb-3 short-info'}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Row>
                  <div className={'col info-item first'}>
                    <UserIcon />
                    <p className={'d-table-cell m-0'}>
                      {listing.number_of_guests}
                      <span> guests</span>
                    </p>
                  </div>
                  <div className={'col info-item'}>
                    <RulerIcon />
                    <p className={'d-table-cell m-0'}>
                    {squareFeet ? `${squareFeet.toLocaleString()}` : 'N/A'}
                      <span> SF</span>
                    </p>
                  </div>
                  <div className={'col info-item last'}>
                    <MoneIcon />
                    <p className={'d-table-cell m-0'}>
                      {listing.pricing
                        ? formatPrice(Number(listing.pricing.average?.replace(',', '')))
                        : '0.00'}
                      <span> nt.</span>
                    </p>
                  </div>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Row>
                  <div className={'col info-item first'}>
                    <BedIcon />
                    <p className={'d-table-cell m-0'}>
                      {listing.bedrooms}
                      <span> room{listing.bedrooms > 1 || listing.bedrooms === '0' ? 's' : null}</span>
                    </p>
                  </div>
                  <div className={'col info-item'}>
                    <BathIcon />
                    <p className={'m-0'}>
                      {listing.bathrooms}
                      <span> Bath{listing.bathrooms > 1 || listing.bathrooms === '0' ? 's' : null}</span>
                    </p>
                  </div>
                  <div className={'col info-item last'}>
                    <FontAwesomeIcon icon={regularStar} className={'me-2 fs-4 fa-icon-sizes'} />
                    <p className={'m-0'}>
                      {isObject(listing.rating) ? listing.rating.avg : '5'}
                      <span> ({isObject(listing.rating) ? listing.rating.total : 25})</span>
                    </p>
                  </div>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col
            lg="12"
            style={{
              marginTop: '32px',
              marginBottom: '48px',
              padding: 0,
            }}
          >
            {images && (
              <ImageGallery
                thumbnailPosition={this.state.thumbnailPosition}
                showFullscreenButton
                showPlayButton
                showBullets
                items={images}
                useBrowserFullscreen
                ref={this.screenRef}
                onClick={this.toggleFullscreen}
                renderCustomControls={this.renderCustomControls}
              />
            )}
          </Col>
        </Row>

        <Row className="parent_container" ref={this.parentBreakdownRef}>
          <Col lg="7" md="12" className="amenities">
            <h5>Amenities</h5>
            <Row className="amenity">
              {listing.amenities &&
                listing.amenities
                  .sort((a, b) => a.name.length - b.name.length)
                  .map((amenity, index) => {
                    if (!skip_amenities_array.includes(amenity.name)) {
                      return (
                        <Col xs="6" sm="6" md="6" lg="4" xl="3" className="amenity-item" key={index}>
                          {iconArray[amenity.name.replaceAll(' ', '_').toLowerCase()]?.isFontAwesomeicon ?
                            <FontAwesomeIcon
                              className={'amenity-item-icon'}
                              icon={iconArray[amenity.name.replaceAll(' ', '_').toLowerCase()]?.icon}
                            />: 
                            <div className='amenity-item-icon'>
                                {iconArray[amenity.name.replaceAll(' ', '_').toLowerCase()]?.icon}
                            </div>
                            }
                          <span>{amenity.name}</span>
                        </Col>
                      );
                    } else return false;
                  })}
            </Row>
            <Row>
            
              <Col lg="12" className="map-preview">
                <h5 className={'text-blue'}>Where you'll be</h5>
                <ListingMap lat={listing?.address?.coordinates?.lat  ||  25.7634} lng={listing?.address?.coordinates?.lng || -80.3731} />
              </Col>
           
            </Row>
            <Row>
              <Col lg="12" className="accordion-section">
                <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="0" ref={this.accordionRefs[0]}>
                    <Accordion.Header onClick={() => this.handleAccordionClick(0)}>Description</Accordion.Header>
                    <Accordion.Body onTransitionEnd={() => this.scrollToAccordionItem(0)} dangerouslySetInnerHTML={{ __html: listing.description }} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="1" ref={this.accordionRefs[1]}>
                    <Accordion.Header onClick={() => this.handleAccordionClick(1)}>The Space</Accordion.Header>
                    <Accordion.Body onTransitionEnd={() => this.scrollToAccordionItem(1)} dangerouslySetInnerHTML={{ __html: listing.the_space }} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="2" ref={this.accordionRefs[2]}>
                    <Accordion.Header onClick={() => this.handleAccordionClick(2)}>The Neighborhood</Accordion.Header>
                    <Accordion.Body onTransitionEnd={() => this.scrollToAccordionItem(2)} dangerouslySetInnerHTML={{ __html: listing.the_neighborhood }} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="3" ref={this.accordionRefs[3]}>
                    <Accordion.Header onClick={() => this.handleAccordionClick(3)}>Getting Around</Accordion.Header>
                    <Accordion.Body onTransitionEnd={() => this.scrollToAccordionItem(3)} dangerouslySetInnerHTML={{ __html: listing.getting_around }} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="4" ref={this.accordionRefs[4]}>
                    <Accordion.Header onClick={() => this.handleAccordionClick(4)}>Why Book With Us Directly?</Accordion.Header>
                    <Accordion.Body onTransitionEnd={() => this.scrollToAccordionItem(4)}>
                      <p>
                        We offer world-class, resort amenities and accommodations at cheaper prices than hotels. Our premium rooms and
                        suites in the Sorrento and Tresor Towers offer unobstructed oceanfront views, spacious and stylish accommodations,
                        and a unique Miami Experience.
                      </p>

                      <h6>Better Rooms, Lower Rates</h6>
                      <p>Our partnerships allow us to get you some of the best rooms at the lowest prices.</p>

                      <h6>Worry-Free Stay</h6>
                      <p>Our 24/7 support will be there for you any time you have a question or need help.</p>

                      <h6>Plan Less, Get More</h6>
                      <p>Our concierge team will get you the best experiences using our relationships.</p>

                      <h6>Even More Perks</h6>
                      <p> spa passes, and more by booking directly with MAK Realty.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5" ref={this.accordionRefs[5]}>
                    <Accordion.Header onClick={() => this.handleAccordionClick(5)}>Other Things To Note</Accordion.Header>
                    <Accordion.Body onTransitionEnd={() => this.scrollToAccordionItem(5)} dangerouslySetInnerHTML={{ __html: listing.other_notes }} />
                  </Accordion.Item>

                  <Accordion.Item eventKey="6" ref={this.accordionRefs[6]}>
                    <Accordion.Header onClick={() => this.handleAccordionClick(6)}>Cancellation Policy</Accordion.Header>
                    <Accordion.Body onTransitionEnd={() => this.scrollToAccordionItem(6)}>
                      Get a 100% refund if you cancel at least 5 days before your check in date. Get a 50% refund if you cancel within 5
                      days. No refund is given on the day of check in or after.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
            {this.state.events?.length > 0 && (
              <Row className="interesting-events">
                <h5>Things to do in Miami during your stay</h5>
                {this.state.events?.map((event,index) => {
                  return (
                    <div className="col-md-12 col-lg-6 equal-size">
                      <div className={`event-item ${index !== this?.state?.events?.length-1 && index !== this?.state?.events?.length-2 ? 'event-item-mb-20' : ''}`}>
                        <div className="event">
                          <div className="info">
                            <div className="event">
                              <div className="name">{event.name}</div>
                              <div className="time">{moment(event.time_start).format('dddd, D MMMM YYYY, h:mm a')}</div>
                            </div>
                            <a target="blank" href={event.event_site_url} className="view-more btn btn-outline-light">
                              View more
                            </a>
                          </div>
                          <div className="img">
                            <img src={event.image_url} alt={event.name} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Row>
            )}
          </Col>
          <Col lg={5}>
              <div className="hidden-md price-breakdown" ref={this.priceBreakdownRef}>
                <div>
                  <div
                    style={{
                      marginBottom: '20px',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {this.advanceNoticePassed() && (
                      <div className="text-blue notice-period">The advance notice period
                        of {this.state.listing.advance_notice_hours} hours has passed</div>
                    )}
                    <DatePickerAndGuest
                      search={this.fetchListing}
                      bookDisabled={this.state.bookDisabled}
                      reservation={this.state.reservations}
                    />
                    <Link
                      to={{
                        pathname: this.state.bookDisabled
                          ? `/listing/${listing.id}`
                          : `/checkout/${listing.id}`,
                        search: `?adults=${this.state.adults}&children=${this.state.children}&checkIn=${this.state.checkIn}&checkOut=${this.state.checkOut}`,
                        state: {
                          reservations: this.state.reservations,
                          bookDisabled: this.state.bookDisabled,
                        },
                      }}
                      className={`book-now-btn ${this.state.bookDisabled || this.advanceNoticePassed() ? 'disabled-link' : ''}`}
                    >
                      BOOK NOW
                  </Link>
                  </div>
                  <Accordion className="breakdownAcardion" flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="price-accordion-title" onClick={this.toggleAccordion}>
                        {this.state.isAccordionOpen ? 'HIDE' : 'SHOW'} PRICE BREAKDOWN
                      </Accordion.Header>
                      <Accordion.Body style={{ padding: '0' }}>
                        <div className="pricing_table_wrapper">
                          <table>
                            <thead>
                            <tr>
                              <td style={{ color: '#1B2540' }}>
                                {moment(this.state.checkOut).diff(this.state.checkIn, 'days')} Night(s), {guests} Guest(s)
                              </td>
                              <td style={{ color: '#1B2540' }}>amount</td>
                            </tr>
                            </thead>
                            <tbody>
                            {listing.pricing &&
                              listing.pricing.breakdown.map((array, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{moment(array.date).format('DD MMM')}</td>
                                    <td>
                                      $
                                      {formatPrice(array.price)}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="pricing_table_wrapper">
                    <table>
                      <tfoot>
                      <tr>
                        <td className="darker">Subtotal</td>
                        <td className="darker">
                          ${listing.pricing ? formatPrice(listing.pricing.subtotal) : 'N/A'}
                        </td>
                      </tr>

                      <tr>
                        <td className="darker">Taxes</td>
                        <td className="darker">
                          ${listing.pricing ? formatPrice(listing.pricing.tax) : 'N/A'}
                        </td>
                      </tr>
                      {listing.pricing.extra_cleaning_fee ? (
                        <tr>
                          <td className="darker">Cleaning fee</td>
                          <td className="darker">
                            ${formatPrice(listing.pricing.extra_cleaning_fee)}
                          </td>
                        </tr>
                      ) : (
                        ''
                      )}
                      <tr>
                        <td className="darker">Transaction fee</td>
                        <td className="darker">
                          ${listing.pricing ? formatPrice(listing.pricing.fee) : 'N/A'}
                        </td>
                      </tr>

                      <tr>
                        <td className="darker" style={{ fontSize: '15px' }}>
                          Total
                        </td>
                        <td className="darker" style={{ fontSize: '15px' }}>
                          ${listing.pricing ? formatPrice(listing.pricing.total) : 'N/A'}
                        </td>
                      </tr>
                      </tfoot>
                    </table>
                  </div>
                <div className="breakdown-btns-wrapper">
                  <button onClick={this.share} className={'breakdown-btn'}>
                    <FontAwesomeIcon icon={faArrowUpFromBracket} />
                    <span>SHARE</span>
                  </button>
                </div>
              </div>
            </div>


          </Col>
        </Row>

        <Row className="mobile-book-panel">
          <div>
            <div className="price">
              ${listing.pricing ? formatPrice(listing.pricing.subtotal) : 'N/A'}
            </div>
            <span>(taxes not included)</span>
          </div>
          <div className="buttons">
            <Button onClick={this.share} variant={'transparent'} className={'rounded-circle'}>
              <FontAwesomeIcon icon={faShare} />
            </Button>

            <Link
              to={
                '/checkout/' +
                listing.id +
                '?adults=' +
                this.state.adults +
                '&children=' +
                this.state.children +
                '&checkIn=' +
                this.state.checkIn +
                '&checkOut=' +
                this.state.checkOut
              }
              className={`btn btn-primary ms-3 mobile-cart-button${
                this.state.bookDisabled || this.advanceNoticePassed() ? 'disabled-link' : ''
              }`}
            >
              <FontAwesomeIcon icon={faCartPlus}></FontAwesomeIcon>
            </Link>
          </div>
        </Row>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}
