import React, { Component } from "react";
import { Button, ButtonGroup, Container, Pagination } from "react-bootstrap";
import './ReservationsIndex.scss';
import Api from "../../../Api";
import moment from "moment/moment";
import { Link } from "react-router-dom";

export class ReservationsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paginatedReservations: { data: [] },
            page: 1,
            status: 'pending'
        }

        this.fetchItems = this.fetchItems.bind(this);
    }

    componentDidMount() {
        this.fetchItems();
    }

    fetchItems() {
        Api.get('/api/manager/pending-reservations?page=' + this.state.page + '&per_page=8&status=' + this.state.status)
            .then((response) => {
                this.setState({ paginatedReservations: response.data, page: response.data.current_page });
            })
            .catch((e) => {
                console.log(e);
            })
    }

    confirm($id) {
        if (!window.confirm('Are you sure you want to confirm this reservation?')) return;
        Api.post('/api/manager/reservation/' + $id + '/confirm')
            .then((response) => {
                this.fetchItems();
            })
            .catch((e) => {
                console.log(e);
            })
    }

    cancel($id) {
        if (!window.confirm('Are you sure you want to cancel this reservation?')) return;
        Api.post('/api/manager/reservation/' + $id + '/cancel')
            .then((response) => {
                this.fetchItems();
            })
            .catch((e) => {
                console.log(e);
            })
    }

    render() {
        return <>
            <Container className="top-container">
                <h2 className="pull-left">Pending approval bookings</h2>
            </Container>
            <Container className="reservations-table">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th>Dates</th>
                            <th>Price</th>
                            <th>Guest</th>
                            <th>Guests no.</th>
                            <th>Phone</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.paginatedReservations.data.map((reservation, index) => (
                            <tr key={reservation.id} className="reservation-item">
                                <td>
                                    <Link to={'/manager/listings/update/' + reservation.listing.id} target="_blank" >
                                        {reservation.listing.name || 'N/A'}
                                    </Link>
                                </td>
                                <td>
                                    {moment(reservation.check_in_date).format('D/M/Y')} - {moment(reservation.check_out_date).format('D/M/Y')}
                                </td>
                                <td>{(reservation.meta_info && reservation.meta_info.total) ? '$' + reservation.meta_info.total : 'N/A'}</td>
                                <td>{reservation.belongs_to.first_name + ' ' + reservation.belongs_to.last_name}</td>
                                <td>{reservation.adults}</td>
                                <td>
                                    <a href={'tel:' + reservation.belongs_to.phone}>{reservation.belongs_to.phone}</a>
                                </td>
                                <td>
                                    <ButtonGroup className="reservation-actions">
                                        <Button size="sm" variant="outline-primary" onClick={() => this.confirm(reservation.id)} >Confirm</Button>
                                        <Button size="sm" variant="outline-warning" onClick={() => this.cancel(reservation.id)} >Cancel</Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Container>

            <Container className="pagination">
                <Pagination className="justify-content-center">
                    <Pagination.First onClick={() => {
                        this.setState({ page: 1 }, () => this.fetchItems());
                    }} />
                    <Pagination.Prev disabled={this.state.page === 1} onClick={() => {
                        this.setState({ page: this.state.page - 1 }, () => this.fetchItems());
                    }} />

                    {
                        [...Array(this.state.paginatedReservations.last_page).keys()].map((page) => {
                            return <Pagination.Item key={page} active={page + 1 === this.state.page} onClick={() => {
                                this.setState({ page: page + 1 }, () => this.fetchItems());
                            }}>{page + 1}</Pagination.Item>
                        })
                    }

                    <Pagination.Next disabled={this.state.page === this.state.paginatedReservations.last_page} onClick={() => {
                        this.setState({ page: this.state.page + 1 }, () => this.fetchItems());
                    }} />
                    <Pagination.Last onClick={() => {
                        this.setState({ page: this.state.paginatedReservations.last_page }, () => this.fetchItems());
                    }} />
                </Pagination>
            </Container>
        </>
    }
}