import React from "react";
import { GoogleMap, Circle, useLoadScript, Marker } from "@react-google-maps/api";
import circleIcon from "../../assets/icons/home3.svg";;

export default function ListingMap({ lat, lng }) {
    const location = { lat, lng };
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });

    const propertyLocation = { lat, lng };
    const radius = 0.25 * 1609.34;

    const circleOptions = {
        fillColor: "#EE4B81",
        fillOpacity: 0.3,
        strokeWeight: 0.2,
        strokeColor: "#EE4B81",
    };

    return (
        <div>
            {isLoaded ? (
                <GoogleMap
                    mapContainerClassName="map-container"
                    center={location}
                    zoom={14}
                    options={{ fullscreenControl: false, zoomControl: false, mapTypeControl: false }}
                >
                    <Circle center={propertyLocation} radius={radius} options={circleOptions} draggable={false} />
                    <Marker position={propertyLocation} icon={circleIcon} />
                </GoogleMap>
            ) : (
                <p>Loading map...</p>
            )}
        </div>
    );
}
