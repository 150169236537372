import React, { Component } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import Api from "../../../Api";
import { Button, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap";
import './ListingPricing.scss';
import { Link } from "react-router-dom";
export default class ListingPricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
          listing: {
            listing_exceptions: []
          },
          newException: {
            date: '',
            price: '',
            block: false,
            nights: 1
          },
          showModal: false,
          editExceptionId: null,
        }

        this.fetchListing = this.fetchListing.bind(this);
        this.storeException = this.storeException.bind(this);
        this.handleDateClick = this.handleDateClick.bind(this);
    }

    fetchListing() {
        Api.get('/api/manager/listing/' + this.props.id)
            .then((response) => {
                this.setState({ listing: response.data });
            })
            .catch((e) => {
                console.log(e);
            })
    }

    componentDidMount() {
        this.fetchListing();
    }

    handleDateClick = (arg) => {
        const todaysException = this.state.listing.listing_exceptions.find((exception) => {
            return exception.date === arg.dateStr;
        });
        if (!todaysException) {
            this.setState({ showModal: true, newException: { date: arg.dateStr, end_date: arg.dateStr } });
        } else {
            this.setState({ showModal: true, newException: {
                date: todaysException.date,
                end_date: todaysException.date,
                price: todaysException.price,
                block: todaysException.blocked,
                nights: todaysException.minimum_nights,
            }, editExceptionId: todaysException.id });
        }
    }

    storeException(e) {
        e.preventDefault();
        const url = '/api/manager/listing/' + this.props.id + '/exception';
        Api.post(url, {
            date: this.state.newException.date,
            end_date: this.state.newException.end_date,
            price: this.state.newException.price,
            block: this.state.newException.block ? 1 : 0,
            nights: this.state.newException.nights
        })
            .then((response) => {
                this.setState({ showModal: false, newException: { date: '', price: '', block: false, nights: 1 } })
                this.fetchListing();
            })
            .catch((e) => {
                console.error(e);
        })
    }

    render() {
        return <>
        <Container className="top-container">
        <h2 className="pull-left">Pricing of listing <i>{this.state.listing.name}</i></h2>

        <div>
            <Link to={"/manager/listings"} className="btn btn-outline-primary pull-right back-btn">← Back to list</Link>
            <Link to={"/manager/listings/update/" + this.props.id} className="btn btn-primary pull-right">Edit listing</Link>
        </div>
        </Container>

        <Container className="main-area pricing">
        <Row>
            <Col md={4}>
                <h3>Exceptions</h3>
                <ListGroup>
                    {this.state.listing.listing_exceptions.map((exception, i) => {
                        return <ListGroup.Item key={i}>
                            <div className="d-flex justify-content-between">

                                <div>{exception.date}, ${exception.price}</div>
                                <Button variant="danger" size="sm" onClick={() => {
                                    Api.delete('/api/manager/listing/exception/' + exception.id)
                                        .then((response) => {
                                            this.fetchListing();
                                        })
                                        .catch((e) => {
                                            console.log(e);
                                        })
                                }}>Delete</Button>
                            </div>
                        </ListGroup.Item>
                    })}
                </ListGroup>

            </Col>
            <Col md={8}>
                <FullCalendar
                    defaultView="dayGridMonth"
                    plugins={[ dayGridPlugin, interactionPlugin ]}
                    events={this.state.listing.listing_exceptions.map((exception) => {
                        return {
                            title: exception.price,
                            start: exception.date,
                            end: exception.date,
                            extendedProps: {
                                exception: exception,
                            }
                        }
                    })}
                    dateClick={this.handleDateClick}
                    eventClick={(evt) => {
                        const exception = evt.event.extendedProps.exception;
                        this.setState({ showModal: true, editExceptionId: exception.id, newException: { date: exception.date, price: exception.price, block: exception.blocked, nights: exception.minimum_nights } })
                    }}
                />
            </Col>
        </Row>
        </Container>

        <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
            <Modal.Header closeButton>
                {this.state.editExceptionId ? 'Update exception' : 'Add exception'}
            </Modal.Header>
            <Modal.Body>
                <Form id="add-exception-form" onSubmit={this.storeException}>
                    <Form.Group>
                        <Form.Label>Start date</Form.Label>
                        <Form.Control required type="date" value={this.state.newException.date} onChange={(e) => this.setState({ newException: { ...this.state.newException, date: e.target.value } })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>End date</Form.Label>
                        <Form.Control required type="date" value={this.state.newException.end_date} onChange={(e) => this.setState({ newException: { ...this.state.newException, end_date: e.target.value } })} />
                    </Form.Group>
                    <Form.Group className="mt-4">
                        <Form.Label>Price</Form.Label>
                        <Form.Control required type="number" value={this.state.newException.price} onChange={(e) => this.setState({ newException: { ...this.state.newException, price: e.target.value } })} />
                    </Form.Group>
                    <Form.Group className="mt-4">
                        <Form.Label>Minimum number of Nights</Form.Label>
                        <Form.Control required type="number" value={this.state.newException.nights} onChange={(e) => this.setState({ newException: { ...this.state.newException, nights: e.target.value } })} />
                    </Form.Group>
                    <Form.Group className="mt-4">
                        <Form.Switch label="Manual block" checked={this.state.newException.block} onChange={(e) => this.setState({ newException: { ...this.state.newException, block: e.target.checked } })} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" form="add-exception-form" variant="primary">Save</Button>
            </Modal.Footer>
        </Modal>

        </>
    }
}