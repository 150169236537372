import {render, handleChangeQtyEvent} from './quantity-selector-helper';
import { KeyCodes } from '../../enums/keyboard';
import { IQuantitySelectorField, QuantitySelectorSettings } from '../../interfaces/quantity-selector';

/**
 * JSOcean Quantity Selector: Desktop Version
 */
class JSOQuantitySelectorDesktop{

    /**
     * properties
     */
    settings: QuantitySelectorSettings;
    onFieldsUpdate: Function;
    fields: IQuantitySelectorField[];
    $root: HTMLElement;
    $eventsRoot: HTMLElement;

    /**
     * init
     * @param {object=} settings
     * @param {Array.<{titleSingular: string, titlePlural: string, codeName: string, quantity: number, step: number, min: number, max: number|undefined, dependantField: Array|undefined}>} fields
     * @param {Function} onFieldsUpdate
     * @param {HTMLElement=} $eventsRoot - events root is used to dispatch events; if it's undefined, $root is used instead
     */
    constructor(settings: QuantitySelectorSettings, fields: IQuantitySelectorField[], onFieldsUpdate: Function, $eventsRoot: HTMLElement) {

        this.settings = settings;
        this.onFieldsUpdate = onFieldsUpdate;

        // deep copy of fields array
        this.fields = JSON.parse(JSON.stringify(fields));

        this.$root = document.createElement('div');
        this.$root.className = `jso-dropdown ${this.settings.dropdownClassName} ${this.settings.animation ? 'jso-' + this.settings.animation : ''}`;

        this.$eventsRoot = $eventsRoot || this.$root;

        // update dropdown view
        this.updateView();
    }

    // ---------- RENDER --------------

    /**
     * update dropdown view
     */
    updateView(){

        // render date picker dropdown
        this.$root.innerHTML = this.render();

        // handle dropdown events
        this.handleDropdownEvents();
    }

    /**
     * render
     */
    render(){

        return `
            <div class="jso-quantity-selector-dropdown-content">
                ${render(this.fields)}
            </div>    
        `;
    }

    // ---------- EVENTS --------------

    /**
     * handle dropdown events
     */
    handleDropdownEvents(){

        /**
         * prevent close / open when click on dropdown content
         */
        const $content = this.$root.querySelector('.jso-quantity-selector-dropdown-content');

        if($content){
            $content.addEventListener('click', (evt: Event) => {

                const $target = evt.target as HTMLElement;

                if($target && $target.classList.contains('jso-quantity-selector-field-btn')){

                    this.onQtyChange(evt, 'button');
                }

                evt.stopPropagation();
            });

            // @ts-ignore
            $content.addEventListener('keydown', (evt: KeyboardEvent) => {

                const $target = evt.target as HTMLElement;

                if ((evt.which === KeyCodes.ENTER || evt.keyCode === KeyCodes.ENTER) && evt.target) {

                    if($target.classList.contains('jso-quantity-selector-field-btn')){
                        this.onQtyChange(evt, 'button');
                    }

                    if($target.classList.contains('jso-quantity-selector-select')){
                        // this.onQtyChange(evt, 'select');

                        evt.stopPropagation();
                    }
                }
            });

            $content.addEventListener('change', evt => {

                const $target = evt.target as HTMLElement;

                if($target && $target.classList.contains('jso-quantity-selector-select')){

                    this.onQtyChange(evt, 'select');
                }
            });
        }
    }

    /**
     * on qty change
     * @param {object} evt
     * @param {string} controlType - 'button' or 'select'
     */
    onQtyChange(evt: Event, controlType: string){

        evt.stopPropagation();
        evt.preventDefault();

        const $target = evt.target as HTMLFormElement;

        // handle qty button click event
        this.handleChangeQty($target, controlType);

        // <button type="button" class="jso-quantity-selector-field-btn jso-quantity-selector-field-btn-plus" data-type="plus" data-code="adults">+</button>
        const type = $target.getAttribute('data-type'); // plus or minus
        const code = $target.getAttribute('data-code'); // adults, children, age or rooms
        const index = Number($target.getAttribute('data-index')) || -1; // for age fields

        // return focus after the render
        const indexParam = (index === -1) ? '' : `[data-index="${index}"]`;

        if(controlType === 'button') {

            const $btn = this.$root.querySelector(`.jso-quantity-selector-field-btn[data-type="${type}"][data-code="${code}"]${indexParam}`) as HTMLFormElement;

            if ($btn) {
                $btn.focus();
            }
        }

        if(controlType === 'select') {

            const $select = this.$root.querySelector(`.jso-quantity-selector-select[data-code="${code}"]${indexParam}`) as HTMLFormElement;

            if ($select) {
                $select.focus();
            }
        }

    }

    /**
     * helper: on qty change
     * @param {HTMLElement} $control - button or select
     * @param {string} controlType - 'button' or 'select'
     */
    handleChangeQty($control: HTMLFormElement, controlType: string){

        // deep copy of fields array
        const fields = JSON.parse(JSON.stringify(this.fields));

        if(handleChangeQtyEvent($control, fields, controlType)){

            this.fields = fields;

            // update dropdown view
            this.updateView();

            // update fields on the parent
            this.onFieldsUpdate(this.fields);
        }
    }
}

export default JSOQuantitySelectorDesktop;