import React, { useState } from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { ReactComponent as Star } from '../../../../assets/icons/blackStar.svg';
import markerIcon from "../../../../assets/icons/map-marker.svg";

const center = {
    lat: 25.81812302049237,
    lng: -80.12141938088601
};

const Map = ({ isMobile }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });
    const redirectToHome = () => {
        window.open(window.location.origin + '?orderByDir=desc')
      };
    return (
        <div className="map" id="map">
            {isMobile ?
                <h2 dangerouslySetInnerHTML={{ __html: "located <br/> in the heart of miami beach" }} />
                :
                <h2>located in the heart of miami beach</h2>}

            <p>4441 Collins Avenue, Miami Beach, FL 33140</p>
            <button onClick={redirectToHome} className="map-button">RESERVE YOUR STAY</button>
            {!isLoaded && <p>Loading map...</p>}
            {isLoaded && (
                <GoogleMap
                    mapContainerClassName="map-container"
                    center={center}
                    zoom={14}
                    options={{ fullscreenControl: false, zoomControl: false, mapTypeControl: false }}
                >
                    <Marker
                        position={center}
                        icon={markerIcon}
                        onMouseOver={() => setShowTooltip(true)}
                        onMouseOut={() => setShowTooltip(false)}
                    />
                    {showTooltip && (
                        <InfoWindow position={center} options={{ pixelOffset: { width: 0, height: 90 } }}>
                            <div className="map-tooltip">
                                <p>Fontainebleau Trésor Penthouse</p>
                                <div>
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                </div>
                            </div>
                        </InfoWindow>
                    )}
                </GoogleMap>
            )}
        </div>
    )
}

export default Map;