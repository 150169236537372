import React from "react";
import "./ListingNotFoundWarning.scss";
import {ReactComponent as CloseRounded} from "../../../assets/icons/closeRounded.svg"

const ListingNotFoundWarning = () => (
  <div className="listing-not-fount-warning">
    <div className="icon-container">
      <CloseRounded />
    </div>
    <div className="text-container">
      <p className="main-text">Sorry, we could not find listings foryour search criteria.</p>
      <p className="secondary-text">
        Please either try a different search, or contact us at <span className="phone">1-(305)-204-6049</span> for more options.
      </p>
    </div>
  </div>
);

export default ListingNotFoundWarning;
