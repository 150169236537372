import React, { Component } from 'react';
import { Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import Api from '../../Api';
import { Link, Redirect } from 'react-router-dom';
import '../../assets/styles/LoginRegister.scss';
import { ReactComponent as DangerRed } from '../../assets/icons/danger-red.svg';

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: [],
      formErrors: [],
      formProcessing: false,
      formSuccess: false,
      show2fa: false,
      showResetPassword: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleResetPassword = this.toggleResetPassword.bind(this);
  }

  toggleResetPassword() {
    this.setState({ showResetPassword: !this.state.showResetPassword });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let { formData = [], formErrors = [], data = [] } = this.state;

    formData[name] = value;
    formErrors[name] = null;
    data[name] = value;

    this.setState({
      formData: formData,
      formErrors: formErrors,
      data: data,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData();
    for (const key in this.state.formData) formData.append(key, this.state.formData[key]);
    this.setState({ formProcessing: true });

    if (this.state.showResetPassword) {
      let loadingToast = toast.loading('Resetting');

      Api.post('/forgot-password', formData)
        .then((response) => {
          if (response) toast.success('Password reset link sent to your email.', { id: loadingToast });
        })
        .catch((error) => {
          if (error.response.data.errors) this.setState({ formErrors: error.response.data.errors });
          toast.error(error.response.data.message, { id: loadingToast });
        })
        .finally((response) => {
          this.setState({ formProcessing: false });
        });
    } else if (this.props.token) {
      let loadingToast = toast.loading('Saving');

      formData.append('token', this.props.token);

      Api.post('/reset-password', formData)
        .then((response) => {
          toast.success('Password reset. You may now log in.', { id: loadingToast });
          this.setState({ formSuccess: true });
        })
        .catch((error) => {
          if (error.response.data.errors) this.setState({ formErrors: error.response.data.errors });
          toast.error(error.response.data.message, { id: loadingToast });
          if (error.response.data.show2fa) this.setState({ show2fa: error.response.data.show2fa });
        })
        .finally((response) => {
          this.setState({ formProcessing: false });
        });
    } else {
      let loadingToast = toast.loading('Signing In');

      Api.get('/sanctum/csrf-cookie').then((response) => {
        Api.post('/login', formData)
          .then((response) => {
            toast.success('You have successfully logged in.', { id: loadingToast });
            this.props.updateFunction();
          })
          .catch((error) => {
            if (error.response.data.errors) this.setState({ formErrors: error.response.data.errors });
            toast.error(error.response.data.message, { id: loadingToast });
            if (error.response.data.show2fa) this.setState({ show2fa: error.response.data.show2fa });
          })
          .finally((response) => {
            this.setState({ formProcessing: false });
          });
      });
    }
  }

  render() {
    if (this.props.user) {
      return <Redirect to={'/'} />;
    } else if (this.props.token && this.state.formSuccess) {
      return <Redirect to={'/login'} />;
    } else {
      return (
        <Container className="loginRegisterMainContainer">
          <Row>
            <Col>
              <h1 className={'loginRegisterTitle'}>
                {!this.state.showResetPassword && !this.props.token ? 'Log In' : 'Forgot Password'}
                {this.props.token && 'Enter New Password'}
              </h1>
            </Col>
          </Row>

          <Row className="loginRegisterPage m-auto">
            <Col md={12} className={'p-0'}>
              <div className="loginFormContainer align-middle">
                {!this.state.showResetPassword && !this.props.token && (
                  <p className={'logInText'}>Please enter your login information below.</p>
                )}
                <Form onSubmit={this.handleSubmit}>
                  {this.state.showResetPassword && !this.props.token && (
                    <p className={'logInText'}>We will send you a temporary link to reset your password.</p>
                  )}

                  {this.props.token && <p className={'logInText'}>Enter your new password below.</p>}
                  {!this.state.show2fa && (
                    <FormGroup className="mb-4 emailContainer">
                      <FormLabel className="label">Email</FormLabel>
                      <FormControl
                        type="text"
                        name="email"
                        className="input"
                        isInvalid={this.state.formErrors && this.state.formErrors.email}
                        placeholder="Enter Email"
                        onChange={this.handleInputChange}
                      />
                      {this.state.formErrors && this.state.formErrors.email && (
                        <div className="errorMsg">
                          <span>{<DangerRed />}</span>
                          {this.state.formErrors.email}
                        </div>
                      )}
                    </FormGroup>
                  )}

                  {this.props.token && (
                    <div className="emailContainer">
                      <Row className={'mb-4'}>
                        <Col>
                          <FormLabel className="label">New Password</FormLabel>
                          <FormControl
                            autocomplete={'new-password'}
                            name={'password'}
                            className="input"
                            type={'password'}
                            onChange={this.handleInputChange}
                            isInvalid={this.state.formErrors && this.state.formErrors.password}
                          />
                          {this.state.formErrors && this.state.formErrors.password && (
                            <div className="errorMsg">
                              <span>{<DangerRed />}</span>
                              {this.state.formErrors.password}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row className={'mb-4'}>
                        <Col>
                          <FormLabel className="label">Confirm Password</FormLabel>
                          <FormControl
                            autocomplete={'new-password'}
                            name={'password_confirmation'}
                            className="input"
                            type={'password'}
                            onChange={this.handleInputChange}
                            isInvalid={this.state.formErrors && this.state.formErrors.password_confirmation}
                          />
                          {this.state.formErrors && this.state.formErrors.password_confirmation && (
                            <div className="errorMsg">
                              <span>{<DangerRed />}</span>
                              {this.state.formErrors.password_confirmation}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}

                  {!this.state.show2fa && !this.state.showResetPassword && !this.props.token && (
                    <>
                      <FormGroup className="passwordContainer">
                        <FormLabel className="label">Password</FormLabel>
                        <FormControl
                          type="password"
                          name="password"
                          className="input"
                          isInvalid={this.state.formErrors && this.state.formErrors.password}
                          placeholder="Enter Password"
                          onChange={this.handleInputChange}
                        />
                        {this.state.formErrors && this.state.formErrors.password && (
                          <div className="errorMsg">
                            <span>{<DangerRed />}</span>
                            {this.state.formErrors.password}
                          </div>
                        )}
                      </FormGroup>
                      <Row className={'rememberMeContainer'}>
                        <Col className={'rememberMeFlex'}>
                          <FormGroup className='checkbox'>
                            <Form.Check
                              type="checkbox"
                              id="remember-me"
                              name="remember"
                              className="label"
                              label="Remember me"
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                          <span className={'cursor-pointer forgot-password'} onClick={this.toggleResetPassword}>
                            Forgot Password
                          </span>
                        </Col>
                      </Row>
                    </>
                  )}

                  {this.state.show2fa && (
                    <FormGroup className="mb-4 inputContainer">
                      <FormLabel className="label">Two Factor Code</FormLabel>
                      <FormControl
                        type="text"
                        name="two_factor_code"
                        className="input"
                        isInvalid={this.state.formErrors && this.state.formErrors.two_factor_code}
                        placeholder="######"
                        onChange={this.handleInputChange}
                      />
                      {this.state.formErrors && this.state.formErrors.two_factor_code && (
                        <div className="errorMsg">
                          <span>{<DangerRed />}</span>
                          {this.state.formErrors.two_factor_code}
                        </div>
                      )}
                    </FormGroup>
                  )}

                  <Row className="buttonsContainer">
                    <Col>
                      <Button size="md" variant="primary" type="submit" className="button login" disabled={this.state.formProcessing}>
                        {this.state.formProcessing && this.props.token ? (
                          <>
                            <Spinner size={'sm'} className={'me-2'} animation={'border'} /> Signing In
                          </>
                        ) : this.state.formProcessing ? (
                          <>
                            <Spinner size={'sm'} className={'me-2'} animation={'border'} /> Signing In
                          </>
                        ) : this.state.showResetPassword || this.props.token ? (
                          'Send Link'
                        ) : (
                          'Log In'
                        )}
                      </Button>

                      {!this.state.showResetPassword && (
                        <Col>
                          <Link to={'/register'} className={'button signup'}>
                            Sign Up
                          </Link>
                        </Col>
                      )}

                      {this.state.showResetPassword && (
                        <Button onClick={this.toggleResetPassword} className={'button signup'}>
                          Go Back
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}
